import { render, staticRenderFns } from "./DossierPage.vue?vue&type=template&id=39e858f0&scoped=true"
import script from "./DossierPage.vue?vue&type=script&lang=js"
export * from "./DossierPage.vue?vue&type=script&lang=js"
import style0 from "./DossierPage.vue?vue&type=style&index=0&id=39e858f0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39e858f0",
  null
  
)

export default component.exports