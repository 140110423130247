<template>
  <div v-if="isVisible" class="modal-overlay">
    <div class="modal-content" ref="modalContent">
      <button v-if="!selectedArticle" @click="$emit('close')" class="close-button" style="margin-top: 5px;">×</button>
      <!-- Left Content -->
      <div class="left-content">
        <h2 style="font-size: 1.5rem; font-weight: 300; margin-bottom: 25px;">
          Synthèse Juridique Assistée
        </h2>
        <div class="textarea-content">
          <textarea
            v-model="localContext"
            rows="5"
            cols="50"
            class="search-input bg-light"
          ></textarea>
        </div>

        <div v-if="isLoadingPoints" class="loading-message">
          <!-- Show loading message or spinner -->
          <div class="title-points">
            <h4 style="font-size: 1.2rem; font-weight: 300">
              <em>Identification des points de droits...</em>
            </h4>
          </div>
        </div>

        <div v-else-if="pointsList.length === 0">
          <!-- Show the button to fetch points -->
          <div class="buttons">
            <button @click="fetchPoints" class="enrichir-button">
              Lancer la synthèse
            </button>
            <button @click="$emit('close')" class="back-button">
              Annuler
            </button>
          </div>
        </div>

        <transition name="fade">
          <div v-if="pointsList.length !== 0" class="points-class">
            <!-- Display the points with editing capabilities -->
            <div class="title-points">
              <h4 style="font-size: 1.2rem; font-weight: 300">
                <em>
                  Notre système effectuera une recherche sur chacun de ces
                  points
                </em>
              </h4>
            </div>
            <ul class="points-list">
              <li
                v-for="(point, index) in pointsList"
                :key="point.id"
                class="point-item"
              >
                <!-- Point Display and Editing -->
                <div v-if="point.isEditing" class="point-display">
                  <textarea
                    v-focus="point.isEditing"
                    v-model="point.text"
                    rows="2"
                    cols="50"
                    class="point-input bg-light"
                  ></textarea>
                  <button @click="savePoint(index)" class="small-button">
                    <!-- Save icon SVG -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                      />
                      <path
                        d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"
                      />
                    </svg>
                  </button>
                </div>
                <div v-else class="point-display">
                  <div class="point-text bg-light">{{ point.text }}</div>
                  <button @click="editPoint(index)" class="small-button">
                    <!-- Edit icon SVG -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"
                      />
                    </svg>
                  </button>
                  <button @click="deletePoint(index)" class="small-button">
                    <!-- Delete icon SVG -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash rounded-1"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"
                      />
                      <path
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
                      />
                    </svg>
                  </button>
                </div>

                <!-- Articles Associated with the Point -->
                <!-- Articles Associated with the Point -->
                <ul class="article-list">
                  <template v-if="isLoadingVisa">
                    <li class="article-item" style="display: flex; align-items: center;">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="spinner-icon"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        style="margin-right: 5px;"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 3a5 5 0 1 1-4.546 2.916.5.5 0 1 1 .908-.418A4 4 0 1 0 8 4V1l3 3-3 3V4z"
                        />
                      </svg>
                        <p class = "loading-message">Recherche d'article en cours....</p>
                      </li>
                  </template>
                  <template v-else-if="hasArticles(point)">
                    <li
                      v-for="article in getArticlesForPoint(point)"
                      :key="article.textId"
                      class="article-item"
                      @click="selectArticle(article)"
                    >
                      {{ article.textNumber }}
                    <button
                      @click="deleteArticleFromPoint(point, article)"
                      class="delete-article-button"
                      title="Supprimer l'article"
                    >
                      <!-- Small delete icon SVG -->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"
                        />
                        <path
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
                        />
                      </svg>
                    </button>
                    </li>
                  </template>
                  <template v-else>
                    <li class="article-item">
                      Aucun article trouvé.
                    </li>
                  </template>
                  <div class="article-search">
                    <input
                      type="text"
                      v-model="point.articleSearchQuery"
                      @input="onArticleSearchInput(point)"
                      placeholder="Ajouter un article"
                      class="article-search-input"
                    />
                    <ul v-if="point.articleSuggestions.length > 0" class="suggestions-list">
                      <li
                        v-for="suggestion in point.articleSuggestions"
                        :key="suggestion.document_key"
                        @click="addArticleToPoint(point, suggestion)"
                        class="suggestion-item"
                      >
                        {{ suggestion.text }}
                      </li>
                    </ul>
                  </div>
                </ul>
              </li>
            </ul>

            <!-- Button to add a new point -->
            <div class="add-point-button">
              <button @click="addPoint" class="small-button" style="display: flex">
                <!-- Plus icon SVG -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-plus-circle"
                  viewBox="0 0 16 16"
                  style="margin-top: 4px; margin-right: 3px;"
                >
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                  />
                  <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                  />
                </svg>
                Ajouter un point
              </button>
            </div>

            <!-- Buttons to start analysis or cancel -->
            <div class="buttons">
              <button
                @click="startAnalysis"
                class="enrichir-button"
                :disabled="!syntheseReady || isLoadingVisa"
                :class="{ 'button-disabled': !syntheseReady || isLoadingVisa }"
              >
                Démarrer l'analyse
              </button>
              <button @click="$emit('close')" class="back-button">
                Annuler
              </button>
            </div>
          </div>
        </transition>
      </div>

      <!-- Right Panel for Article Text -->
      <div v-if="selectedArticle" class="article-panel">
        <div class="article-panel-header">
          <h3>{{ selectedArticle.textNumber }}</h3>
          <button @click="closeArticlePanel" class="close-button">×</button>
        </div>
        <div class="article-details">
          <!-- Centered Hierarchy -->
          <div class="article-hierarchy">
            <p v-html="formattedHierarchy"></p>
          </div>
          <!-- Centered Content -->
          <div class="article-content">
            <p>{{ selectedArticle.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getFirebaseToken } from '@/services/api';
import { auth, db } from "@/firebase/firebaseConfig";
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { fetchArticleSuggestions } from '@/services/autocompleteService';


export default {
  name: 'SyntheseModal',
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      }
    }
  },

  mounted() {
    this.fetchPointsFromFirestore();
  },

  props: ['isVisible', 'context', 'dossierId'],
  data() {
    return {
      localContext: this.context, // Initialize localContext with the passed context
      isLoadingVisa: false,
      formattedHierarchy: '',
      pointsList: [], // Initialize with an empty array
      isLoadingPoints: false,
      pointIdCounter: 0,
      isAddingArticleMap: {},
      visaList: {},
      syntheseReady: false,
      selectedArticle: null,
    };
  },

  beforeDestroy() {
    if (this.unsubscribeVisaListener) {
      this.unsubscribeVisaListener();
    }
  },

  watch: {
    context(newVal) {
      this.localContext = newVal; // Update localContext when context prop changes
    },
    'pointsList.length': function (newLength, oldLength) {
      if (oldLength === 0 && newLength > 0) {
        this.$nextTick(() => {
          const modalContent = this.$refs.modalContent;
          const h4Element = this.$el.querySelector('.points-class .title-points h4');
          if (h4Element && modalContent) {
            const h4Rect = h4Element.getBoundingClientRect();
            const modalRect = modalContent.getBoundingClientRect();
            const scrollTop = modalContent.scrollTop + (h4Rect.top - modalRect.top);
            modalContent.scrollTo({ top: scrollTop, behavior: 'smooth' });
          }
        });
      }
    },
  },

  methods: {
    fetchPoints() {
      if (!this.localContext.trim()) {
        alert('Le contexte est vide.');
        return;
      }
      this.isLoadingPoints = true;
      getFirebaseToken().then(token => {
        if (!token) {
          alert('Erreur d\'authentification. Veuillez réessayer.');
          this.isLoadingPoints = false;
          return;
        }

        axios.post(`https://api.query-juriste.com/copilot/get_points`, {
          contexte: this.localContext,
          userId: auth.currentUser.uid,
          dossierId: this.dossierId || this.$route.params.dossierId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          console.log(response.data)
          // Map the received points to include isEditing property
          this.pointsList = (response.data.points || []).map(pointText => ({
            text: pointText,
            originalText: pointText,
            isEditing: false,
            id: this.pointIdCounter++,
            articleSearchQuery: '',
            articleSuggestions: [],
          }));
          this.isLoadingPoints = false;
          // Scroll to bottom after points are loaded
          this.$nextTick(() => {
            this.scrollToBottom();
            this.getVisa(this.localContext, this.pointsList.map(point => point.text));
          });
        }).catch(error => {
          console.error('Error fetching points:', error);
          alert('Erreur lors de la récupération des points.');
          this.isLoadingPoints = false;
        });

      }).catch(error => {
        console.error('Error fetching token:', error);
        alert('Erreur lors de la récupération du token. Veuillez réessayer.');
        this.isLoadingPoints = false;
      });
    },

    deleteArticleFromPoint(point, article) {
      // Find the index of the article in the visaList for the point
      const articles = this.visaList[point.text];
      if (!articles) return;
      console.log('Articles:', articles);
      console.log('Article to delete:', article);

      const articleIndex = articles.findIndex(
        (a) => a.textId === article.textId
      );

      if (articleIndex !== -1) {
        // Remove the article from the visaList
        articles.splice(articleIndex, 1);

        // If there are no more articles under the point, remove the point's entry
        if (articles.length === 0) {
          delete this.visaList[point.text];
        }

        // Save the updated visaList to Firestore
        this.saveVisaListToFirestore();
      }
    },

    async fetchFullArticle(documentKey) {
      try {
        const response = await fetch('https://api.query-juriste.com/copilot/get_article', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ documentKey }),
        });

        if (response.ok) {
          const data = await response.json();
          return data.article || null;
        } else {
          console.error('Failed to fetch article:', response.statusText);
          return null;
        }
      } catch (error) {
        console.error('Error fetching article:', error);
        return null;
      }
    },

    async addArticleToPoint(point, suggestion) {
      // Fetch the full article using the documentKey
      const fullArticle = await this.fetchFullArticle(suggestion.document_key);
      console.log('Full article:', fullArticle);

      if (!fullArticle) {
        alert('Failed to fetch the full article.');
        return;
      }

      if (!this.visaList[point.text]) {
        this.visaList[point.text] = [];
      }

      // Prevent duplicates
      const existingArticle = this.visaList[point.text].find(
        article => article.documentKey === suggestion.document_key
      );

      if (!existingArticle) {
        // Add the full article data to the visaList
        this.visaList[point.text].push({
          textNumber: fullArticle.article_name,
          content: fullArticle.content,
          hierarchy: fullArticle.full_hierarchy,
          textId: fullArticle.article_id,
          texte: fullArticle.content_hierarchy,
          // Include other necessary fields
        });
        console.log('Updated visaList:', this.visaList);

        // Save the updated visaList to Firestore
        await this.saveVisaListToFirestore();
      }

      // Clear the search input and suggestions
      point.articleSearchQuery = '';
      point.articleSuggestions = [];
    },

    async fetchPointsFromFirestore() {
      this.isLoadingPoints = true; // Show loading initially
      try {
        const dossierId = this.dossierId || this.$route.params.dossierId;
        const userId = auth.currentUser.uid; // Ensure the user is authenticated
        const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
        const docSnap = await getDoc(dossierDocRef);

        if (docSnap.exists()) {
          const data = docSnap.data();

          // Update pointsList based on the keys of the visa data
          if (data.visa) {
            this.visaList = data.visa;
            console.log('Fetched visa from Firestore:', this.visaList);

            this.pointsList = Object.keys(this.visaList).map((key, index) => ({
              text: key,
              index: index,
              originalText: key,
              isEditing: false,
              id: this.pointIdCounter++,
              articleSearchQuery: '',
              articleSuggestions: [],
            }));
            console.log('Updated pointsList from visa keys:', this.pointsList);
          }

          // Check if visa is ready
          if (data.isAnalysing === 'visaDone') {
            this.syntheseReady = true;
            this.isLoadingVisa = false;
          } else if (data.isAnalysing === 'failed') {
            this.syntheseReady = false;
            this.isLoadingVisa = false;
          } else if (data.isAnalysing === 'pending') {
            // Visa is still being processed, start listening
            this.isLoadingVisa = true;
            this.listenToVisaStatus(dossierId, userId);
          } else {
            // No visa process started
            this.syntheseReady = false;
            this.isLoadingVisa = false;
          }
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        this.isLoadingPoints = false; // Ensure to set loading false in finally block
      }
    },


    getVisa(context, pointList) {
      console.log('Getting visa for:', context, pointList);
      this.isLoadingVisa = true;
      this.syntheseReady = false; // Reset the readiness state
      const dossierId = this.dossierId || this.$route.params.dossierId;
      const userId = auth.currentUser.uid;
      getFirebaseToken().then(token => {
        if (!token) {
          this.$toast.error('Authentication error. Please try again.');
          this.isLoadingVisa = false;
          return;
        }
        axios.post('https://api.query-juriste.com/copilot/get_visa', {
          contexte: context,
          point_list: pointList,
          userId: userId,
          dossierId: dossierId,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          console.log('Visa response:', response.data);

          // Start listening to Firestore for updates
          this.listenToVisaStatus(dossierId, userId);
        }).catch(error => {
          console.error('Error while fetching visa:', error);
          this.$toast.error('Error during visa retrieval.');
          this.isLoadingVisa = false;
        });
      });
    },

    async onArticleSearchInput(point) {
      if (point.articleSearchQuery.length < 2) {
        point.articleSuggestions = [];
        return;
      }
      const suggestions = await fetchArticleSuggestions(point.articleSearchQuery);
      point.articleSuggestions = suggestions;
      console.log('Article suggestions:', point.articleSuggestions);
    },

    async saveVisaListToFirestore() {
      const dossierId = this.dossierId || this.$route.params.dossierId;
      const userId = auth.currentUser.uid;
      const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
      try {
        console.log('Dossier document reference:', dossierDocRef);
        console.log('Updating visa list in Firestore:', this.visaList);
        await updateDoc(dossierDocRef, {
          visa: this.visaList,
        });
        console.log('Visa list updated in Firestore');
      } catch (error) {
        console.error('Error updating visa list in Firestore:', error);
      }
    },

    deletePoint(index) {
      const point = this.pointsList[index];
      // Remove the point's articles from visaList
      delete this.visaList[point.text];
      this.pointsList.splice(index, 1);
      // Save the updated visaList to Firestore
      this.saveVisaListToFirestore();
    },

    savePoint(index) {
      const point = this.pointsList[index];
      if (point.text.trim() === '') {
        alert('Le point ne peut pas être vide.');
        return;
      }
      // If the point text has changed, update visaList
      if (point.originalText && point.originalText !== point.text) {
        // Copy the articles to the new text
        this.visaList[point.text] = this.visaList[point.originalText] || [];
        // Remove old entry from visaList
        delete this.visaList[point.originalText];
        point.originalText = point.text;
        // Save the updated visaList to Firestore
        this.saveVisaListToFirestore();
      }
      point.isEditing = false;
    },

    listenToVisaStatus(dossierId, userId) {
      if (!userId || !dossierId) {
        console.error('Missing userId or dossierId for Firestore listener.');
        return;
      }

      const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);

      this.unsubscribeVisaListener = onSnapshot(
        dossierDocRef,
        (docSnapshot) => {
          if (!docSnapshot.exists()) {
            console.error('Dossier document does not exist.');
            return;
          }

          const data = docSnapshot.data();
          if (data.isAnalysing === 'visaDone') {
            console.log(data)
            // Visa processing is complete

            this.visaList = data.visa || {};
            this.syntheseReady = true;
            this.isLoadingVisa = false;
            console.log('Visa list:', this.visaList);

            // Optionally, unsubscribe the listener if no longer needed
            if (this.unsubscribeVisaListener) {
              this.unsubscribeVisaListener();
              console.log('Listener unsubscribed after visa done');
            }
          } else if (data.isAnalysing === 'failed') {
            // Handle failure
            this.$toast.error('Visa processing failed.');
            this.isLoadingVisa = false;

            // Optionally, unsubscribe the listener
            if (this.unsubscribeVisaListener) {
              this.unsubscribeVisaListener();
              console.log('Listener unsubscribed after visa failed');
            }
          }
          // Handle other statuses ('pending', etc.) if needed
        },
        (error) => {
          console.error('Error listening to Firestore:', error);
        }
      );
    },

    startAnalysis() {
      if (this.pointsList.length === 0) {
        alert('Aucun point n\'a été sélectionné.');
        return;
      }
      // Extract the text from each point object
      const pointsTexts = this.pointsList.map(point => point.text);
      // Emit an event to the parent component with context and pointsList
      this.$emit('startAnalysis', this.localContext, pointsTexts, this.visaList);
      this.$emit('close'); // Close the modal
    },
    addPoint() {
      this.pointsList.push({
        text: '',
        originalText: '',
        isEditing: true,
        id: this.pointIdCounter++,
        articleSearchQuery: '',
        articleSuggestions: [],
      });
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },

    scrollToBottom() {
      const modalContent = this.$refs.modalContent;
      if (modalContent) {
        modalContent.scrollTop = modalContent.scrollHeight;
      }
    },

    editPoint(index) {
      this.pointsList[index].isEditing = true;
    },

    hasArticles(point) {
      return (
        Array.isArray(this.visaList[point.text]) &&
        this.visaList[point.text].length > 0
      );
    },

    getArticlesForPoint(point) {
      return this.visaList[point.text] || [];
    },

    // Method to handle article selection
    selectArticle(article) {
      this.selectedArticle = article;
      this.formatHierarchy();
    },
    formatHierarchy() {
      // Split the hierarchy string into an array based on ' > '
      const hierarchyArray = this.selectedArticle.hierarchy.split(' > ');
      // Create HTML string with each level on a new line
      this.formattedHierarchy = hierarchyArray.join('<br>');
    },

    // Method to close the article panel
    closeArticlePanel() {
      this.selectedArticle = null;
    },
  },
};
</script>

<style scoped>

.modal-content {
  display: flex;
  flex-direction: row; /* Ensure items are placed in a row */
  flex-wrap: nowrap; /* Prevent items from wrapping to the next line */
  background-color: white;
  padding: 0px; /* Kept as in your original code */
  border-radius: 8px;
  width: 90%;
  height: 90vh;
  overflow: hidden; /* Hide overflow to prevent scrollbars on the main container */
}

/* Left Content */
.left-content {
  flex: 2;
  padding: 20px; /* Kept as in your original code */
  overflow-y: auto; /* Allow scrolling within the left content */
}

/* Right Panel for Article Text */
.article-panel {
  flex: 1;
  background-color: #f5efeb; /* Kept as in your original code */
  padding: 10px; /* Kept as in your original code */
  font-family: 'Times New Roman', Times, serif; /* Kept as in your original code */
  border-left: 1px solid #ccc;
  overflow-y: auto; /* Allow scrolling within the article panel */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
}

.article-panel-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 30px; /* Add some padding at the top for spacing */
}

.close-button {
  position: absolute;
  top: -10px; /* Position above the header content */
  right: 10px; /* Adjust distance from the right */
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.article-panel-header h3 {
  margin: 0;
}

.article-details {
  width: 100%;
}

.article-hierarchy {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #555;
  line-height: 1.5;
}

.article-hierarchy p {
  margin: 0;
  padding: 0;
}

.article-content {
  margin-top: 10px; /* Kept as in your original code */
  font-size: 1rem;
  line-height: 1.6;
}

.article-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.article-item {
  margin: 0;
  padding: 5px 5px; /* Kept as in your original code */
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  font-size: 0.5rem; /* Kept as in your original code */
  border-radius: 15px;
  cursor: pointer;
}

.article-item:hover {
  background-color: #e0e0e0;
}

.article-info {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #555;
}

.fade-enter-active,
.fade-leave-active {
  transition: clip-path 3s ease-out, opacity 3s;
  clip-path: inset(0 0 0 0);
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  clip-path: inset(0 0 100% 0);
}

.delete-article-button {
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.delete-article-button svg {
  fill: black; /* Red color for delete icon */
}

.delete-article-button:hover svg {
  fill: grey; /* Darker red on hover */
}

.loading-message {
  color: transparent;
  background: linear-gradient(to left, lightgray 50%, black 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  animation: slide 3s linear infinite;
  cursor: default;
  pointer-events: none;
}

@keyframes slide {
  to {
    background-position: -100%;
  }
}

.points-class {
  margin-top: 20px;
}

.title-points {
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.textarea-content {
  height: 20vh;
}

.search-input {
  width: 100%;
  height: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.enrichir-button {
  background-color: #1c1c1a;
  color: #f4f4f4;
  border: none;
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
}
.back-button {
  background-color: #444;
  color: #f4f4f4;
  border: none;
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
}

.small-button {
  margin-left: 5px;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.small-button:hover {
  background-color: #e0e0e0;
}
.point-item {
  margin-bottom: 10px;
}

.points-list {
  list-style-type: none;
  padding: 0;
}

.point-input {
  width: 80%;
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.point-text {
  padding: 5px;
  border-radius: 16px;
  font-size: 1.1rem;
  background-color: #f8f9fa;
  margin-right: 10px;
  flex: 1;
}

.point-display {
  display: flex;
  align-items: center;
  flex: 1;
}

/* New styling for the add point button */
.add-point-button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.add-visa-button {
  font-size: 0.7rem;
  display: flex;
  justify-content: flex-start;
  padding: 6px 10px;
}

.add-point-button .enrichir-button {
  padding: 8px 12px;
}

.article-info {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #555;
}

.button-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.article-search {
  position: relative;
  font-size: 0.7rem;
}

.article-search-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  background: white;
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 12px;
  max-height: 150px;
  overflow-y: auto;
}

.suggestion-list li {
  margin: 0px;
}

.suggestion-item {
  padding: 5px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
</style>
