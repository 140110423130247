// services/autocompleteService.js

export async function fetchArticleSuggestions(query) {
  if (query.length < 2) return [];

  try {
    const response = await fetch('https://api.query-juriste.com/copilot/autocomplete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query }),
    });

    if (response.ok) {
      const data = await response.json();
      return data.suggestions || [];
    } else {
      console.error('Failed to fetch suggestions:', response.statusText);
      return [];
    }
  } catch (error) {
    console.error('Error fetching suggestions:', error);
    return [];
  }
}
