<template>
  <transition name="fade">
    <div>
      <h2 class='title-cartographie'>Cartographie de l'analyse</h2>
      <div class="graph-container" :class="{ 'with-panel': isSommairePanelVisible }" ref="graphContainer" id="graph-container">
        <!-- The graph will be rendered inside this div -->
      </div>
      <div v-if="isSommairePanelVisible" class="sommaire-panel">
        <button @click="closeSommairePanel" class="close-button">&times;</button>

        <!-- Sommaire Content -->
        <div class="sommaire-content" v-if="titlePanel && analysisContent.length">
          <h3>{{ titlePanel }}</h3>
          <p>
            <span v-for="(segment, idx) in analysisContent" :key="idx">
              <template v-if="segment.type === 'text'">
                {{ segment.content }}
              </template>
              <template v-else-if="segment.type === 'citation'">
                <a href="#" @click.prevent="handleCitationClick(segment.content)"><u>Voir article</u></a>
              </template>
              <template v-else-if="segment.type === 'bold'">
                <strong>{{ segment.content }}</strong>
              </template>
              <template v-else-if="segment.type === 'article'">
                <ul>
                  <li>
                    <a href="#" @click.prevent="onNodeClick(segment.data)">{{ segment.content }}</a>
                  </li>
                </ul>
              </template>
            </span>
          </p>
        </div>

        <!-- Article Analysis Content -->
        <transition name="fade">
          <div v-if="articleAnalysisContent" class="sommaire-content" id="article-analysis">
            <h3>{{ titleArticle }}</h3>
            <p>
              <span v-for="(segment, idx) in articleAnalysisContent" :key="idx">
                <template v-if="segment.type === 'text'">
                  {{ segment.content }}
                </template>
                <template v-else-if="segment.type === 'citation'">
                  <a href="#" @click.prevent="openTextModal(articleId, titleArticle)"><u>{{ segment.content }}</u></a>
                </template>
                <template v-else-if="segment.type === 'bold'">
                  <strong>{{ segment.content }}</strong>
                </template>
                <template v-else-if="segment.type === 'decision'">
                  <ul>
                    <li style="border-bottom: 1px solid #333;">
                      <a href="#" @click.prevent="onDecisionSegmentClick(segment)">{{ segment.content }}</a>
                    </li>
                  </ul>
                </template>
              </span>
            </p>
          </div>
        </transition>

        <!-- Decision Analysis Content -->
        <transition name="fade">
          <div style = 'border-top: 1px solid #333;' v-if="decisionAnalysisContent" class="sommaire-content" id="decision-analysis">
            <h3 id="decision-title-panel">{{ titleDecision }}</h3>
            <div class = "decision-link">
              <a href="#" @click.prevent="openTextModal(decisionId, titleDecision, '')"><u>Voir decision complète</u></a>
            </div>
            <p>
              <span v-for="(segment, idx) in decisionAnalysisContent" :key="idx">
                <template v-if="segment.type === 'text'">
                  {{ segment.content }}
                </template>
                <template v-else-if="segment.type === 'citation'">
                  <a href="#" @click.prevent="openTextModal(decisionId, titleDecision, segment.content)"><u>{{ segment.content }}</u></a>
                </template>
                <template v-else-if="segment.type === 'bold'">
                  <strong>{{ segment.content }}</strong>
                </template>
              </span>
            </p>
          </div>
        </transition>
      </div>
      <TextModal
        ref="textModal"
        :isVisible="showTextModal"
        :title="modalTitle"
        :text="modalText"
        :citation="modalCitation"
        @close="closeTextModal"
      />
    </div>
  </transition>
</template>


<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { doc, getDoc } from 'firebase/firestore';
import TextModal from './TextModal.vue';
import * as d3 from 'd3';

export default {
  props: ['pointsList', 'dossierName'],

  components: {
    TextModal,
  },

  data() {
    return {
      activeArticleId: null,
      modalCitation: "",
      activeArticleIdText: null,
      articleTextProcessed: "",
      articleSummaries: {},
      articleText: "",
      articleTitle: "",
      isTextPanelVisible: false,
      selectedSommaireContent: null,
      isSommairePanelVisible: false,
      analysisContent: [],
      titlePanel: "",
      articleAnalysisContent: null,
      titleArticle: "",
      articleId: "",
      showTextModal: false,
      modalText: "",
      modalTitle: "",
      decisionAnalysisContent: null,
      titleDecision: "",
      decisionId: "",
      visaList: [], // Initialize visaList
    };
  },

  computed: {
    uniquePoints() {
      return [...new Set(this.pointsList.map(analysis => analysis.point))];
    }
  },

  mounted() {
    this.createGraph();
    this.fetchVisaList();
  },

  watch: {
    pointsList(newVal) {
      if (newVal && newVal.length > 0) {
        this.createGraph();
      }
    }
  },

  methods: {
    breakContent(content) {
      return content.replace(/\n/g, '<br>');
    },

    resetPanelData() {
      this.titlePanel = '';
      this.analysisContent = [];
      this.titleArticle = '';
      this.articleId = '';
      this.articleAnalysisContent = null;
      this.titleDecision = '';
      this.decisionId = '';
      this.decisionAnalysisContent = null;
    },


    createGraph() {
      const vm = this;
      console.log('Creating graph with points:', this.pointsList);
      const container = this.$refs.graphContainer;
      const width = container.clientWidth;
      const height = container.clientHeight;

      // Remove existing SVG if it exists
      d3.select(container).select('svg').remove();

      // Create SVG element
      const svg = d3.select(container)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height])
        .attr('preserveAspectRatio', 'xMidYMid meet');

      // Append a group element that will contain all content and be transformed by zoom
      const g = svg.append('g');

      // Apply zoom and pan behavior to the SVG, transforming the 'g' group
      svg.call(d3.zoom()
        .scaleExtent([0.1, 10])
        .on('zoom', (event) => {
          g.attr('transform', event.transform);
        })
      );

      // Function to extract article names from textNumber
      function extractArticleName(text) {
        const regex = /\barticle\s+([A-Z\d\-.]+)/i;
        const match = text.match(regex);
        if (match) {
          return match[1]; // The article name (e.g., "L. 322-1")
        }
        return null;
      }

      // Function to extract articles from pertinence
      function extractArticles(pertinence) {
        if (!pertinence) return [];
        const regex = /^-\s*(artLEGIARTI\d{12}):/gmi;
        const matches = pertinence.replace(/\*\*/g, '').match(regex);
        if (matches) {
          return matches.map(match => match.replace(/^-|\s*:\s*$/g, '').trim());
        }
        console.log('Extracted articles:', matches);
        return [];
      }

      // Function to extract pourvoi number from mention title
      function extractPourvoiNumber(title) {
        if (typeof title !== 'string') {
          console.warn('extractPourvoiNumber received a non-string title:', title);
          return null;
        }
        const regex = /\b(\d{2}-\d{2}\.\d{3}|\d{2}\/\d{5}|\d{6}|\d{2}[A-Z]{2}\d{5,})\b/;
        const match = title.match(regex);
        if (match) return match[0];
        return null;
      }

      // Create hierarchical data structure
      const rootData = {
        name: this.dossierName,
        children: this.pointsList.map((pointData, pointIndex) => {
          const pointChildren = [];
          const sommaireSections = this.parseSommaire(pointData.sommaire);
          const includedDecisionPourvois = new Set();

          // Extract article names from visa list
          pointData.visa.forEach(article => {
            // Extract article name from article.textNumber
            article.articleName = extractArticleName(article.textNumber) || article.textNumber;
          });

          sommaireSections.forEach((section, sectionIndex) => {
            const parsedSegments = this.parseSommaireContent(section.content);
            const idSegments = parsedSegments.filter(seg => seg.type === 'idNumber');
            const articleIds = idSegments.map(seg => seg.content);

            const nameSegments = parsedSegments.filter(seg => seg.type === 'articleName');
            const articleNamesInSommaire = nameSegments.map(seg => seg.content);

            const articlesForSection = pointData.visa.filter(article => {
              const idsInArticle = article.textId.match(/\b(artLEGIARTI\d{12})\b/gi) || [];
              const articleNameInArticle = article.articleName;

              const hasMatchingId = idsInArticle.some(id => articleIds.map(aid => aid.toLowerCase()).includes(id.toLowerCase()));
              const hasMatchingName = articleNamesInSommaire.some(name => name.toLowerCase() === articleNameInArticle.toLowerCase());

              return hasMatchingId || hasMatchingName;
            });

            const articleNodes = articlesForSection.map((article, articleIndex) => {
              const decisionNodes = (article.mentions || []).map(mention => {
                const pourvoiNumber = extractPourvoiNumber(mention);
                if (pourvoiNumber && pointData.decisions) {
                  const decision = pointData.decisions.find(dec => extractPourvoiNumber(dec.title) === pourvoiNumber);
                  if (decision) {
                    includedDecisionPourvois.add(pourvoiNumber);
                    return {
                      name: decision.title,
                      id: `decision-${pourvoiNumber}`,
                      group: 'decision',
                      content: decision.pertinence,
                      pourvoiNumber: pourvoiNumber,
                      children: decision.mentions_article_data
                        ? decision.mentions_article_data.map((articleData, index) => {
                            return {
                              name: articleData.article_name,
                              id: `autre-article-${articleData.article_id}-${index}`,
                              group: 'autre-article',
                              data: articleData,
                            };
                          })
                        : [],
                    };
                  }
                }
                return null;
              }).filter(d => d !== null);

              return {
                mentions: article.mentions,
                index: articleIndex,
                name: article.textNumber,
                textId: article.textId,
                id: `article-${article.textId}-${sectionIndex}`,
                group: 'article',
                fullTexte: article.content,
                hierarchy: article.hierarchy,
                originalTextId: article.textId,
                articles: extractArticles(article.pertinence),
                children: decisionNodes,
              };
            });

            pointChildren.push({
              name: section.title,
              id: `sommaire-${pointIndex}-${sectionIndex}`,
              group: 'sommaire',
              children: articleNodes,
            });
          });

          // Identify orphan decisions
          const orphanDecisions = (pointData.decisions || []).filter(decision => {
            const pourvoiNumber = extractPourvoiNumber(decision.title);
            return pourvoiNumber && !includedDecisionPourvois.has(pourvoiNumber);
          });

          // Create "Decision pertinente" node
          if (orphanDecisions.length > 0) {
            const decisionNodes = orphanDecisions.map((decision, index) => {
              const pourvoiNumber = extractPourvoiNumber(decision.title);
              return {
                name: decision.title,
                decisionId: decision.id,
                index: index,
                id: `decision-orphan-${pourvoiNumber}`,
                group: 'decision',
                content: decision.pertinence,
                pourvoiNumber: pourvoiNumber,
                children: decision.mentions_article_data
                  ? decision.mentions_article_data.map((articleData, index) => {
                      return {
                        name: articleData.article_name,
                        id: `autre-article-${articleData.article_id}-${index}`,
                        group: 'autre-article',
                        data: articleData,
                      };
                    })
                  : [],
              };
            });

            pointChildren.push({
              name: 'Decision pertinente',
              id: `decision-pertinente-${pointIndex}`,
              group: 'decision-pertinente',
              children: decisionNodes,
            });
          }

          return {
            name: pointData.point,
            id: `point-${pointIndex}`,
            group: 'point',
            children: pointChildren,
          };
        }),
      };


      // Create a hierarchical layout
      const root = d3.hierarchy(rootData, d => d.children);

      const nodeWidth = 120; // Adjust as needed
      const nodeHeight = 300; // Adjust as needed

      const treeLayout = d3.tree()
        .nodeSize([nodeWidth, nodeHeight])
        .separation((a, b) => {
          return a.parent === b.parent ? 1 : 1.5;
        });

      treeLayout.separation(function(a, b) {
        if (a.parent === b.parent) {
          if (['sommaire', 'decision-pertinente'].includes(a.data.group) && ['sommaire', 'decision-pertinente'].includes(b.data.group)) {
            return 1;
          }
          return 1;
        } else {
          return 1;
        }
      });


      treeLayout(root);

      // *** Adjust positions of 'article', 'loose-article', 'decision', and 'article-mention' nodes to prevent label overlaps ***
      adjustArticleNodePositions(root);

      // Center the tree in the SVG
      g.attr('transform', `translate(50, 50)`);

      // Create links (edges)
      const link = g.selectAll('.link')
        .data(root.links())
        .enter().append('path')
        .attr('class', 'link')
        .attr('fill', 'none')
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.6)
        .attr('stroke-width', 2)
        .attr('d', d3.linkVertical()
          .x(d => d.x)
          .y(d => d.y)
        );

      const nodes = root.descendants();
      const xMin = d3.min(nodes, d => d.x);
      const xMax = d3.max(nodes, d => d.x);
      const yMin = d3.min(nodes, d => d.y);
      const yMax = d3.max(nodes, d => d.y);

      const padding = 50; // Adjust padding as needed

      const widthScale = (width - 2 * padding) / (xMax - xMin || 1);
      const heightScale = (height - 2 * padding) / (yMax - yMin || 1);
      const scale = Math.min(widthScale, heightScale);

      const translateX = (width / 2) - ((xMin + xMax) / 2) * scale;
      const translateY = padding - yMin * scale;

      // Create currentTransform
      let currentTransform = d3.zoomIdentity.translate(translateX, translateY).scale(scale);

      // Apply initial transform to group g
      g.attr('transform', currentTransform);

      // Define zoom behavior
      const zoom = d3.zoom()
          .scaleExtent([0.1, 10])
          .on('zoom', (event) => {
              currentTransform = event.transform;
              g.attr('transform', currentTransform);
          });

      // Apply zoom behavior to SVG and set initial transform
      svg.call(zoom)
          .call(zoom.transform, currentTransform);

      g.attr('transform', `translate(${translateX},${translateY}) scale(${scale})`);

      // Create nodes
      const nodeSelection = g.selectAll('.node')
        .data(root.descendants())
        .enter().append('g')
        .attr('class', 'node')
        .attr('transform', d => `translate(${d.x},${d.y})`)
        .call(d3.drag()
          .filter(function(event) {
            return !event.target.classList.contains('clickable');
          })
          .on('start', dragStarted)
          .on('drag', dragged)
          .on('end', dragEnded)
        );

      // Append elements based on node group
      nodeSelection.each(function(d) {
        if (d.depth === 0) {
          // Skip rendering for the dummy root
        } else if (d.data.group === 'point') {
          // Define card dimensions
          const cardWidth = window.innerWidth * 0.25; // 25% of viewport width
          const cardHeight = window.innerHeight * 0.15; // 15% of viewport height

          if (isSafari()) {
            // Use native SVG for Safari
            d3.select(this).append('rect')
              .attr('width', cardWidth)
              .attr('height', cardHeight)
              .attr('x', -cardWidth / 2)
              .attr('y', -cardHeight / 2)
              .attr('fill', '#f8f9fa');  // Bootstrap's bg-light color

            const textSafari = d3.select(this).append('text')
              .attr('x', -cardWidth / 2 + 10)  // Small padding from the left edge
              .attr('y', -cardHeight / 2 + 20) // Starting y position at the top
              .attr('fill', 'black')
              .style('dominant-baseline', 'hanging'); // Adjusts the way the text's baseline is calculated

            let fontSize = 24; // Starting font size
            const maxTextHeight = cardHeight - 20; // Account for padding
            let textHeight;

            // Adjust font size to fit inside the rectangle
            do {
              // Clear any existing text and tspans
              textSafari.text('');
              textSafari.selectAll('tspan').remove();

              textSafari.style('font-size', fontSize + 'px');

              // Set the text to check its height
              textSafari.text(d.data.name);
              const bbox = textSafari.node().getBBox();
              textHeight = bbox.height;

              if (textHeight > maxTextHeight) {
                fontSize -= 1; // Decrease font size and try again
              }
            } while (textHeight > maxTextHeight && fontSize > 6);

            // No tspan adjustments needed as we're not using them
          } else {
            // Use foreignObject for other browsers
            const foreignObject = d3.select(this).append('foreignObject')
              .attr('width', cardWidth)
              .attr('height', cardHeight)
              .attr('x', -cardWidth / 2)
              .attr('y', -cardHeight / 2);

            const div = foreignObject.append('xhtml:div')
              .attr('class', 'card bg-light')
              .style('width', '100%')
              .style('height', '100%')
              .style('display', 'flex')
              .style('align-items', 'center')
              .style('justify-content', 'center')
              .style('text-align', 'center')
              .style('overflow', 'hidden');

            const textSpan = div.append('span')
              .attr('class', 'card-text')
              .style('display', 'inline-block')
              .style('white-space', 'normal')
              .style('word-break', 'break-word')
              .style('overflow', 'hidden')
              .style('text-overflow', 'ellipsis')
              .text(d.data.name);

            setTimeout(() => {
              adjustFontSizeToFit(textSpan.node(), cardWidth, cardHeight);
            }, 0);
          }
        } else if (d.data.group === 'sommaire' || d.data.group === 'autre-sommaire') {
          const circleRadius = 75; // Adjust the radius as needed

          // Define different colors for different sommaire groups if desired
          const fillColor = d.data.group === 'sommaire' ? '#ff7f0e' : '#1f77b4'; // Example: orange for 'sommaire', blue for 'autre-sommaire'

          // Append a circle with the specified radius
          d3.select(this).append('circle')
            .attr('r', circleRadius)
            .attr('class', 'clickable')
            .attr('fill', fillColor)
            .on('click', function(event, d) {
              vm.onSommaireClick(d); // Call the Vue method
            });

          // Add text inside the circle
          const textElement = d3.select(this).append('text')
            .attr('class', 'clickable')
            .attr('text-anchor', 'middle')
            .on('click', function(event, d) { // Attach the same click handler
              vm.onSommaireClick(d);
            });

          let fontSize = 24; // Starting font size
          textElement.style('font-size', fontSize + 'px');

          const maxTextWidth = circleRadius * 2 * 0.4; // 80% of circle's diameter
          const maxTextHeight = circleRadius * 2 * 0.4; // 80% of circle's diameter

          let lineCount;
          let textHeight;

          // Adjust font size to fit inside the circle
          do {
            textElement.text(''); // Clear any existing text
            textElement.style('font-size', fontSize + 'px');

            // Wrap text into lines that fit within maxTextWidth
            lineCount = wrapText(textElement, d.data.name, maxTextWidth);

            const bbox = textElement.node().getBBox();
            textHeight = bbox.height;

            if (textHeight > maxTextHeight) {
              fontSize -= 1; // Decrease font size and try again
            }
          } while (textHeight > maxTextHeight && fontSize > 6);

          // Center text vertically
          const totalLineHeight = lineCount * fontSize * 1.1; // lineCount * fontSize * lineHeight
          const yOffset = -totalLineHeight / 2 + fontSize / 2;

          textElement.selectAll('tspan')
            .attr('y', (_, i) => yOffset + i * fontSize * 1.1);

          }  else if (d.data.group === 'decision-pertinente') {
          // **Rendering "Decision pertinente" Node**
          const circleRadius = 75;
          const fillColor = '#8c564b';  // Adjust color as desired

          // Append a circle
          d3.select(this).append('circle')
            .attr('r', circleRadius)
            .attr('class', 'clickable')
            .attr('fill', fillColor)
            .on('click', function(event, d) {
              vm.onDecisionPertinenteClick(d);
            });

          // Add text inside the circle
          const textElement = d3.select(this).append('text')
            .attr('class', 'clickable')
            .attr('text-anchor', 'middle')
            .on('click', function(event, d) {
              vm.onDecisionPertinenteClick(d);
            });

          let fontSize = 18; // Reduced Starting font size // Starting font size
          textElement.style('font-size', fontSize + 'px');

          const maxTextWidth = circleRadius * 2 * 0.8; // 80% of circle's diameter
          const maxTextHeight = circleRadius * 2 * 0.8; // 80% of circle's diameter

          let lineCount;
          let textHeight;

          // Adjust font size to fit inside the circle
          do {
            textElement.text(''); // Clear any existing text
            textElement.style('font-size', fontSize + 'px');

            // Wrap text into lines that fit within maxTextWidth
            lineCount = wrapText(textElement, d.data.name, maxTextWidth);

            const bbox = textElement.node().getBBox();
            textHeight = bbox.height;

            if (textHeight > maxTextHeight) {
              fontSize -= 1; // Decrease font size and try again
            }
          } while (textHeight > maxTextHeight && fontSize > 6);

          // Center text vertically
          const totalLineHeight = lineCount * fontSize * 1.1; // lineCount * fontSize * lineHeight
          const yOffset = -totalLineHeight / 2 + fontSize / 2;

          textElement.selectAll('tspan')
            .attr('y', (_, i) => yOffset + i * fontSize * 1.1);
        } else if (d.data.group === 'article' || d.data.group === 'autre-article') {
          // **Existing Rendering for Article Nodes**
          // Define different colors for different article groups if desired
          const fillColor = d.data.group === 'article' ? '#2ca02c' : '#d62728'; // Example: green for 'article', red for 'autre-article'

          // Append a circle for the article node
          d3.select(this).append('circle')
            .attr('r', 10)
            .attr('fill', fillColor)
            .attr('class', 'clickable')
            .on('click', function(event, d) {
              vm.onNodeClick(d.data); // Pass entire data object to the handler
            });

          // Append the article textNumber above the circle
          d3.select(this).append('text')
            .attr('dy', -15)
            .attr('text-anchor', 'middle')
            .text(d.data.name) // d.data.name now holds textNumber
            .style('font-size', '12px');

          // **New: Append Extracted Articles Below the Article Node**
          if (d.data.articles && d.data.articles.length > 0) {
            d.data.articles.forEach((article, index) => {
              d3.select(this).append('text')
                .attr('x', 0)
                .attr('y', 15 + index * 15) // Adjust Y position as needed
                .attr('text-anchor', 'middle')
                .attr('class', 'article-label')
                .style('font-size', '10px') // Optional: Adjust font size
                .style('fill', '#555') // Optional: Adjust text color
                .text(article);
            });
          }
        } else if (d.data.group === 'decision') {
          // **New Rendering for Decision Nodes**
          const decisionRadius = 8; // Smaller radius for decision nodes
          const decisionFillColor = '#9467bd'; // Purple color for decisions

          // Append a circle for the decision node
          d3.select(this).append('circle')
            .attr('r', decisionRadius)
            .attr('fill', decisionFillColor)
            .attr('class', 'clickable decision-node')
            .on('click', function(event, d) {
              vm.onDecisionClick(d.data); // Handle decision click
            });

          // Append the pourvoi number above the decision node
          d3.select(this).append('text')
            .attr('dy', -10)
            .attr('text-anchor', 'middle')
            .text(d.data.pourvoiNumber) // Display pourvoi number
            .style('font-size', '10px')
            .style('pointer-events', 'none');
        } else if (d.data.group === 'article-mention') {
          // **New Rendering for Article Mention Nodes**
          const mentionRadius = 5; // Small radius for mention nodes
          const mentionFillColor = '#17becf'; // Distinct color for mentions

          // Append a circle for the article mention node
          d3.select(this).append('circle')
            .attr('r', mentionRadius)
            .attr('fill', mentionFillColor)
            .attr('class', 'clickable article-mention-node')
            .on('click', function(event, d) {
              vm.onArticleMentionClick(d.data); // Handle article mention click
            });

          // Append the article mention name below the node
          d3.select(this).append('text')
            .attr('dy', 15)
            .attr('text-anchor', 'middle')
            .text(d.data.name)
            .style('font-size', '10px');
        }
      });

      adjustDecisionNodePositions(root);

      // Define drag event handlers
      function dragStarted(event, d) {
        console.log('Drag started:', d);
        d3.select(this).raise().attr('stroke', 'black');
      }

      function wrapText(textElement, text, maxWidth) {
        textElement.text(''); // Clear any existing text

        const words = text.split(/\s+/);
        let line = [];
        let lineNumber = 0;
        const lineHeight = 1.1; // em units
        let tspan = textElement.append('tspan').attr('x', 0).attr('dy', 0);

        for (let i = 0; i < words.length; i++) {
          line.push(words[i]);
          tspan.text(line.join(' '));
          const textLength = tspan.node().getComputedTextLength();

          if (textLength > maxWidth && line.length > 1) {
            line.pop();
            tspan.text(line.join(' '));
            line = [words[i]];
            tspan = textElement.append('tspan')
              .attr('x', 0)
              .attr('dy', `${lineHeight}em`)
              .text(words[i]);
            lineNumber++;
          }
        }

        return lineNumber + 1; // Return the number of lines
      }

      function adjustFontSizeToFit(element, maxWidth, maxHeight) {
        let fontSize = 24; // Starting font size
        element.style.fontSize = fontSize + 'px';

        // Measure text dimensions
        let textWidth = element.scrollWidth;
        let textHeight = element.scrollHeight;

        // Reduce font size until text fits within width and height
        while ((textWidth > maxWidth || textHeight > maxHeight) && fontSize > 6) {
          fontSize -= 1;
          element.style.fontSize = fontSize + 'px';
          textWidth = element.scrollWidth;
          textHeight = element.scrollHeight;
        }
      }

      function adjustDecisionNodePositions(root) {
        // Collect all article nodes
        const articleNodes = root.descendants().filter(d =>
          d.data.group === 'article'
        );

        // Collect decision nodes under articles
        const decisionNodesUnderArticles = root.descendants().filter(d =>
          d.data.group === 'decision' && d.parent && d.parent.data.group === 'article'
        );

        // Collect decision nodes under "Decision pertinente"
        const decisionNodesUnderPertinente = root.descendants().filter(d =>
          d.data.group === 'decision' && d.parent && d.parent.data.group === 'decision-pertinente'
        );

        if (decisionNodesUnderPertinente.length === 0) return;

        // Determine the maximum y-position among article nodes and decision nodes under articles
        const maxY = d3.max([...articleNodes, ...decisionNodesUnderArticles], d => d.y);

        // **Modify the vertical offset to 60**
        const verticalOffset = 60; // Set vertical offset to 60 units

        // Set the y-position of decision nodes under "Decision pertinente" to be below the maxY by 60 units
        decisionNodesUnderPertinente.forEach(node => {
          node.y = maxY + verticalOffset;
        });

        // Update positions of links and nodes
        link.attr('d', d3.linkVertical()
          .x(d => d.x)
          .y(d => d.y)
        );

        nodeSelection.attr('transform', d => `translate(${d.x},${d.y})`);
      }

      function dragged(event, d) {
          const dx = event.dx / currentTransform.k;
          const dy = event.dy / currentTransform.k;

          d.x += dx;
          d.y += dy;

          d3.select(this)
              .attr('transform', `translate(${d.x},${d.y})`);

          // Update connected links
          link.attr('d', d3.linkVertical()
              .x(d => d.x)
              .y(d => d.y)
          );
      }

      function isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      }

      function dragEnded(event, d) {
        console.log('Drag ended:', d);
        d3.select(this).attr('stroke', null);
      }

      // Adjust positions of 'article' and 'loose-article' nodes to prevent label overlaps
      function adjustArticleNodePositions(root) {
        const articleNodes = root.descendants().filter(d =>
          ['article-mention', 'article'].includes(d.data.group) // Exclude 'article-mention'
        );

        // Sort all article nodes by y position
        articleNodes.sort((a, b) => a.y - b.y);

        // Define minimum vertical distance between nodes to prevent overlap
        const minDistance = 50; // Adjust based on node size and label size

        if (articleNodes.length === 0) return;

        // Get the y position of the first node as baseY
        const baseY = articleNodes[0].y;

        // Iterate through article nodes and adjust y positions in cycles
        articleNodes.forEach((node, index) => {
          const cycle = index % 2;

          // Calculate new y position based on cycle
          node.y = baseY + cycle * minDistance;
        });
      }

      adjustAutreArticleNodePositions(root);

      function adjustAutreArticleNodePositions(root) {
        const nodes = root.descendants();

        // Define the vertical offset between parent and child nodes

        const verticalOffset = 60; // Example offset value

        nodes.forEach((node) => {
          if (node.parent && node.parent.data.group === 'decision') {
            const children = node.parent.children;

            if (!children) return;

            // Position 'autre-article' nodes below their parent decision node
            children.forEach((child) => {
              if (child.data.group === 'autre-article') {
                child.y = node.parent.y + verticalOffset;
              }
            });

            // Sort all 'autre-article' nodes by y position
            const sortedArticles = children
              .filter((child) => child.data.group === 'autre-article')
              .sort((a, b) => a.y - b.y);

            // Define minimum vertical distance between nodes to prevent overlap
            const minDistance = 50; // Adjust based on node size and label size

            if (sortedArticles.length === 0) return;

            // Get the y position of the first node as baseY
            let baseY = sortedArticles[0].y;

            // Iterate through article nodes and adjust y positions
            sortedArticles.forEach((child, index) => {
              const cycle = index % 2;

              // Calculate new y position based on cycle
              child.y = baseY + cycle * minDistance;

              // Update baseY for the next node to ensure minimum distance
              baseY += minDistance;
            });
          }
        });

        // Update positions of links and nodes
        link.attr('d', d3.linkVertical().x((d) => d.x).y((d) => d.y));

        nodeSelection.attr('transform', (d) => `translate(${d.x},${d.y})`);
      }

      // *** New Function: Handle Decision Node Click ***
      // This function should be added within the createGraph method to handle clicks on decision nodes
      // For example, it can open a modal or display details about the decision
      // Here, we'll log the decision data
      // To properly handle this, ensure that the Vue instance can access this method
      // Therefore, define it in the Vue methods below



    },


    onArticleMentionClick(data) {
      console.log('Article mention clicked:', data);
      // Implement the logic you want when an article mention node is clicked
      // For example, open a modal or display article details
    },

    extractArticleName(text) {
      const regex = /\barticle\s+([A-Z\d\-.]+)/i;
      const match = text.match(regex);
      if (match) {
        return match[1];
      }
      return null;
    },

    async onDecisionSegmentClick(segment) {
      // Handle click on a decision in the sommaire content
      // Set the decision's pertinence content to display
      this.decisionAnalysisContent = this.parseContent(segment.data.content);
      console.log(segment.data)
      this.titleDecision = segment.data.name;
      this.decisionId = segment.data.decisionId;

      console.log('starting to scroll');
      await this.$nextTick();

      const container = document.querySelector('.sommaire-panel');
      const elementSummary = document.querySelector(`#decision-analysis`);
      console.log('Element:', elementSummary);
      if (elementSummary) {
        const elementPosition = elementSummary.getBoundingClientRect().top + container.scrollTop - container.getBoundingClientRect().top;
        console.log('Element Position:', elementPosition);
        container.scroll({ top: elementPosition - 20, behavior: 'smooth' });
      }

      // Clear article-related content
      // this.articleAnalysisContent = null;
      // this.titleArticle = "";
      // this.articleId = "";
    },

    async fetchVisaList() {
      const userId = auth.currentUser.uid;
      const dossierId = this.$route.params.dossierId;
      const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
      try {
        const docSnap = await getDoc(dossierDocRef);
        if (docSnap.exists()) {
          console.log("Dossier data:", docSnap.data());
          this.visaList = docSnap.data().visa;  // Ensure this matches your Firestore field name
          this.createGraph(); // Recreate the graph with the fetched visaList
        } else {
          console.error("No such dossier!");
        }
      } catch (error) {
        console.error("Error getting dossier document:", error);
      }
    },

    async openTextModal(decisionId, title, segment) {
      console.log('Opening text modal for article:', decisionId);
      console.log('Article summaries:', title);
      const docRef = doc(db, 'cases', decisionId);
      console.log('docRef:', docRef);
      const docSnap = await getDoc(docRef);
      console.log('docSnap:', docSnap.data());
      this.showTextModal = true;
      if (docSnap.data().texte) {
        this.modalText = docSnap.data().texte;
      } else {
        this.modalText = 'Texte non disponible.';
      }
      this.modalTitle = title;
      this.modalCitation = segment;


      // If segment isn't an empty string, highlight the citation
      this.$nextTick(() => {
        if (this.$refs.textModal) {
          this.$refs.textModal.highlightCitation();
        }
      });
    },

    closeTextModal() {
      this.showTextModal = false;
    },

    handleCitationClick(citation) {
      const article = this.findArticleByCitation(citation);
      if (article) {
        this.articleAnalysisContent = this.getArticleContentPanel(article);
        //this.articleAnalysisContent = this.parsePertinence(analysis, /\b(artLEGIARTI\d{12})\b/g);
      } else {
        console.log('No article found for citation:', citation);
        this.articleAnalysisContent = [
          { type: 'text', content: 'No detailed analysis available for this article.' }
        ];
      }
    },

    getArticleContentPanel(data) {
      this.titleArticle = data.textNumber; // Use textNumber as the title
      console.log('Article data:', data);
      const content = this.getArticleContentFromSommaire(data)

      return this.parseContent(content) || "No detailed content available.";
    },

    findArticleByCitation(citation) {
      if (!citation) {
        console.error('Citation is null or undefined.');
        return null;
      }

      const regex = /\b(artLEGIARTI\d{12})\b/i;
      const normalizedCitation = citation;

      console.log('Looking for citation:', normalizedCitation);

      for (const pointData of this.pointsList) {
        if (!pointData.visa) continue;

        for (const article of pointData.visa) {
          console.log('Checking article:', article.textId);
          if (!article.textId) {
            console.warn('Article textId is missing:', article);
            continue;
          }

          // Get all matches and normalize them for comparison
          const matches = article.textId.match(regex);
          if (matches && matches.map(m => m.toLowerCase()).includes(normalizedCitation.toLowerCase())) {
            console.log('Match found:', article.textId);
            return article;
          }
        }
      }

      console.warn('No match found for citation:', normalizedCitation);
      return null;
    },

    filteredAnalyses(point) {
      return this.pointsList.filter(analysis => analysis.point === point);
    },

    closeModal() {
      this.isTextPanelVisible = false;
      this.$emit('close');
    },

    closeArticleText() {
      this.isTextPanelVisible = false;
      this.activeArticleId = null;
    },

    openText(articleId, articleTitle) {
      this.isTextPanelVisible = true;
      if (this.activeArticleId === articleId) {
        this.activeArticleId = null;
        this.isTextPanelVisible = false;
      } else {
        this.activeArticleId = articleId;
        const docRef = doc(db, 'visa', articleId); // Updated collection name to 'visa'
        getDoc(docRef)
          .then(docSnap => {
            if (docSnap.exists()) {
              this.articleText = docSnap.data().texte;
              this.articleTitle = articleTitle;
              this.articleTextProcessed = this.articleText;
            } else {
              console.log('No article text found for:', articleId);
            }
          })
          .catch(error => {
            console.error('Error fetching article text:', error);
          });
      }
    },

    // In your onSommaireClick method
    onSommaireClick(nodeData) {
      this.resetPanelData();
      console.log('Sommaire clicked:', nodeData);
      if (nodeData.data.group === 'sommaire') {
        const sommaireContent = this.getSommaireContent(nodeData);
        const formattedText = this.parsePertinence(sommaireContent, /\b(artLEGIARTI\d{12})\b/gi);
        this.titlePanel = nodeData.data.name;
        this.analysisContent = formattedText.map(segment => {
          if (segment.type === 'idNumber') {
            return {
              type: 'article',
              content: segment.content,
              data: this.findArticleById(segment.content) // New method to find article by ID
            };
          }
          return segment;
        });
        this.articleAnalysisContent = null;
      } else if (nodeData.data.group === 'autre-sommaire') {
        // Handle 'autre-sommaire' by using looseArticles
        if (nodeData.data.looseArticles && nodeData.data.looseArticles.length > 0) {
          const childArticles = nodeData.data.looseArticles;
          this.titlePanel = nodeData.data.name;
          // Format the articles as clickable items
          this.analysisContent = childArticles.map(article => ({
            type: 'article',
            content: article.name,
            data: article
          }));
          this.articleAnalysisContent = null; // Clear any existing article analysis
        } else {
          // No loose articles found
          this.titlePanel = nodeData.data.name;
          this.analysisContent = [
            { type: 'text', content: 'Aucun article pertinent disponible.' }
          ];
          this.articleAnalysisContent = null;
        }
      }
      this.isSommairePanelVisible = true;
    },

    onNodeClick(data) {
      this.resetPanelData();
      console.log('Node clicked:', data);

      // Parse the pertinence/content of the article
      const articleContent = this.getArticleContent(data);
      const formattedText = this.parseContent(articleContent);

      // Set the panel content for the article
      this.titleArticle = data.name; // Use the article name
      if (data.group === 'autre-article') {
        this.articleId = data.data.article_id; // For 'autre-article', get article_id from data
      } else {
        this.articleId = data.originalTextId;
      }
      this.articleAnalysisContent = formattedText;

      // Clear decision-related content
      this.decisionAnalysisContent = null;
      this.titleDecision = '';
      this.decisionId = '';

      // Ensure the sommaire panel is visible
      this.isSommairePanelVisible = true;
    },

    closePanel() {
      this.isSommairePanelVisible = false;
    },

    getSommaireContent(nodeData) {
      // Extract indices from the node ID
      const sommaireMatch = nodeData.data.id.match(/sommaire-(\d+)-(\d+)/);
      if (sommaireMatch) {
        const pointIndex = sommaireMatch[1];
        const sommaireIndex = sommaireMatch[2];

        // Access the correct 'sommaire' content
        const pointData = this.pointsList[parseInt(pointIndex)];
        console.log('Point data:', pointData);
        const sommaireSections = this.parseSommaire(pointData.sommaire);
        console.log('Sommaire sections:', sommaireSections);
        const sommaireContent = sommaireSections[parseInt(sommaireIndex)].content;

        return sommaireContent;
      }

      // If not a regular 'sommaire', return empty
      return "";
    },

    getArticleContent(nodeData) {
      console.log('Getting article content for node:', nodeData);
      if (nodeData.group === 'autre-article') {
        const articleData = nodeData.data || {};
        const hierarchy = articleData.full_hierarchy || '';
        const texte = articleData.content || '';

        // Format the hierarchy
        const hierarchySegments = hierarchy.split('>').map(segment => {
          const [firstPart, ...rest] = segment.trim().split(':'); // Split on ':'
          // Format first part as bold and concatenate the rest if it exists
          return `**${firstPart.trim()}**${rest.length > 0 ? ': ' + rest.join(':').trim() : ''}`;
        });

        // Join the segments with a newline character
        const formattedHierarchy = hierarchySegments.join('\n');

        // Concatenate 'formattedHierarchy' with three newline characters and 'texte'
        const content = `${formattedHierarchy}\n\n\n${texte}`;
        console.log('Autre-article content:', content);

        // Return the concatenated content or a fallback message
        return content || "No detailed content available.";
      } else {
        // Existing code for 'article' nodes
        // Extract the original textId from the node ID
        const originalTextId = nodeData.originalTextId;
        console.log('Original textId:', originalTextId);

        // Ensure that 'hierarchy' and 'texte' properties exist
        const hierarchy = nodeData.hierarchy || '';
        const texte = nodeData.fullTexte || '';

        // Split 'hierarchy' by '>' and process each segment
        const hierarchySegments = hierarchy.split('>').map(segment => {
          const [firstPart, ...rest] = segment.trim().split(':'); // Split on ':'
          // Format first part as bold and concatenate the rest if it exists
          return `**${firstPart.trim()}**${rest.length > 0 ? ': ' + rest.join(':').trim() : ''}`;
        });

        // Join the segments with a newline character
        const formattedHierarchy = hierarchySegments.join('\n');

        // Concatenate 'formattedHierarchy' with three newline characters and 'texte'
        const content = `${formattedHierarchy}\n\n\n${texte}`;
        console.log('Article content:', content);

        // Return the concatenated content or a fallback message
        return content || "No detailed content available.";
      }
    },

    getArticleContentFromSommaire(articleData) {
      console.log('Getting article content for:', articleData);
      const hierarchy = articleData.hierarchy || '';
      const texte = articleData.content || '';

      // Format the hierarchy
      const hierarchySegments = hierarchy.split('>').map(segment => {
        const [firstPart, ...rest] = segment.trim().split(':'); // Split on ':'
        // Format first part as bold and concatenate the rest if it exists
        return `**${firstPart.trim()}**${rest.length > 0 ? ': ' + rest.join(':').trim() : ''}`;
      });

      // Join the segments with a newline character
      const formattedHierarchy = hierarchySegments.join('\n');

      // Concatenate 'formattedHierarchy' with three newline characters and 'texte'
      const content = `${formattedHierarchy}\n\n\n${texte}`;
      console.log('Autre-article content:', content);
      // Return the concatenated content or a fallback message
      return content || "No detailed content available.";
    },


    closeSommairePanel() {
      this.isSommairePanelVisible = false;
      this.selectedSommaireContent = null;
      this.articleAnalysisContent = null;
      this.decisionAnalysisContent = null; // Reset decision content
      this.titleDecision = ""; // Reset decision title
      this.decisionId = ""; // Reset decision ID
      // If using unified panel properties
      // this.currentPanelContent = null;
      // this.currentPanelTitle = null;
      // this.currentContentType = null;
    },

    parseSommaireContent(content) {
      const segments = [];
      const regexPattern = '\\b(artLEGIARTI\\d{12})\\b|\\barticle\\s+([A-Z\\d\\.-]+)\\b';
      const regex = new RegExp(regexPattern, 'gi'); // 'g' for global, 'i' for case insensitive
      let lastIndex = 0;
      let match;
      while ((match = regex.exec(content)) !== null) {
        if (match.index > lastIndex) {
          segments.push({
            type: 'text',
            content: content.slice(lastIndex, match.index),
          });
        }
        if (match[1]) {
          segments.push({
            type: 'idNumber',
            content: match[1],
          });
        } else if (match[2]) {
          segments.push({
            type: 'articleName',
            content: match[2],
          });
        }
        lastIndex = regex.lastIndex;
      }
      if (lastIndex < content.length) {
        segments.push({
          type: 'text',
          content: content.slice(lastIndex),
        });
      }
      return segments;
    },

    parseContent (text) {
      const regex = /"([^"]*)"|\*\*([^*]+)\*\*/g
      const segments = [];
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(text)) !== null) {
        if (match.index > lastIndex) {
          segments.push({
            type: 'text',
            content: text.slice(lastIndex, match.index)
          });
        }

        if (match[1]) {
          segments.push({
            type: 'citation',
            content: match[0]
          });
        } else if (match[2]) {
          segments.push({
            type: 'bold',
            content: match[2]
          });
        }
        lastIndex = regex.lastIndex;
      }

      if (lastIndex < text.length) {
        segments.push({
          type: 'text',
          content: text.slice(lastIndex)
        });
      }

      return segments;

    },


    parsePertinence(text, regex) {
      const segments = [];
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(text)) !== null) {
        if (match.index > lastIndex) {
          segments.push({
            type: 'text',
            content: text.slice(lastIndex, match.index)
          });
        }

        if (match[1]) {
          segments.push({
            type: 'citation',
            content: match[0]
          });
        } else if (match[2]) {
          segments.push({
            type: 'bold',
            content: match[2]
          });
        }
        lastIndex = regex.lastIndex;
      }

      if (lastIndex < text.length) {
        segments.push({
          type: 'text',
          content: text.slice(lastIndex)
        });
      }

      return segments;
    },

    scrollToArticle(articleNumber) {
      const analysisId = this.getAnalysisId(articleNumber);
      const element = this.$el.querySelector('#' + CSS.escape(analysisId));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.log('Element not found for article number:', articleNumber);
      }
    },

    parseSommaire(text) {
      const sections = [];
      const regex = /- @\[?([^\]\n]*)\]?\s*([\s\S]*?)(?=- @\[|(?=- @\S)|$)/g;
      let match;
      while ((match = regex.exec(text)) !== null) {
        const title = match[1].trim();
        const content = match[2].trim();
        sections.push({
          title: title,
          content: content
        });
      }
      return sections;
    },

    parseTitle(title) {
      const segments = [];
      const regex = /\*\*([^*]+)\*\*/g;
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(title)) !== null) {
        if (match.index > lastIndex) {
          segments.push({
            type: 'text',
            content: title.slice(lastIndex, match.index)
          });
        }
        segments.push({
          type: 'bold',
          content: match[1]
        });
        lastIndex = regex.lastIndex;
      }

      if (lastIndex < title.length) {
        segments.push({
          type: 'text',
          content: title.slice(lastIndex)
        });
      }

      return segments;
    },

    goToCitation(citation, articleId, articleTitle) {
      if (!this.isTextPanelVisible || this.activeArticleId !== articleId) {
        this.openText(articleId, articleTitle);
      }

      if (!this.articleText) {
        const waitForArticleText = () => {
          if (this.articleText) {
            this.highlightCitation(citation);
          } else {
            setTimeout(waitForArticleText, 100);
          }
        };
        waitForArticleText();
      } else {
        this.highlightCitation(citation);
      }
    },
    highlightCitation(citation) {
      if (!this.articleText) {
        console.error("Le texte de l'article n'est pas disponible.");
        return;
      }

      let citationWithoutQuotes = citation.trim();
      // Remove surrounding quotes if present
      if (citationWithoutQuotes.startsWith('"') && citationWithoutQuotes.endsWith('"')) {
        citationWithoutQuotes = citationWithoutQuotes.slice(1, -1).trim();
      }
      if (citationWithoutQuotes.endsWith('.')) {
        citationWithoutQuotes = citationWithoutQuotes.slice(0, -1);
      }

      citationWithoutQuotes = citationWithoutQuotes.toLowerCase();
      const normalizedArticleText = this.articleText.toLowerCase();

      const index = normalizedArticleText.indexOf(citationWithoutQuotes);
      if (index === -1) {
        console.log('Citation non trouvée dans le texte de l\'article.');
        return;
      }

      const beforeText = this.articleText.substring(0, index);
      const afterText = this.articleText.substring(index + citationWithoutQuotes.length);
      const matchText = this.articleText.substring(index, index + citationWithoutQuotes.length);

      this.articleTextProcessed = `${beforeText}<span class="citation-highlight">${matchText}</span>${afterText}`;

      this.$nextTick(() => {
        this.scrollToCitation();
      });
    },


    scrollToCitation() {
      const textPanel = this.$el.querySelector('.text-panel');
      if (textPanel) {
        const citationElement = textPanel.querySelector('.citation-highlight');
        if (citationElement) {
          citationElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          console.log('Failed to scroll to citation.');
        }
      } else {
        console.log('Text panel not found.');
      }
    },

    getAnalysisId(articleNumber) {
      return 'analysis-' + articleNumber.replace(/[^a-zA-Z0-9-_]/g, '');
    },

    getArticleNumber(article) {
      const regex = /\b(artLEGIARTI\d{12})\b/i;

      let match = article.textId ? article.textId.match(regex) : null;
      if (match) {
        return match[0];
      }

      match = article.textNumber ? article.textNumber.match(regex) : null;
      if (match) {
        return match[0];
      }

      return null;
    },


    scrollToAnalysis(analysisId) {
      const id = this.getAnalysisId(analysisId);
      const element = this.$el.querySelector('#' + CSS.escape(id));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.log('Element not found for analysis id:', analysisId);
      }
    },
    getPointId(point) {
      return 'point-' + point.replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-_]/g, '');
    },
    scrollToPoint(point) {
      const id = this.getPointId(point);
      const element = this.$el.querySelector('#' + CSS.escape(id));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.log('Element not found for point:', point);
      }
    },

    // *** New Helper Method: Find Article by ID ***
    findArticleById(articleId) {
      for (const pointData of this.pointsList) {
        const article = pointData.visa.find(a => a.textId === articleId); // Changed from 'articles' to 'visa' and 'id' to 'textId'
        if (article) {
          return article;
        }
      }
      return null;
    },

    // *** New Method: Handle Decision Node Click ***
    onDecisionPertinenteClick(nodeData) {
      this.resetPanelData();
      //console.log('Decision pertinente clicked:', nodeData);
      // Prepare the content to display in the panel
      const decisionNodes = nodeData.children || [];
      console.log('Decision nodes:', decisionNodes);
      const formattedContent = decisionNodes.map(decision => ({
        type: 'decision',
        content: decision.data.name,
        data: decision.data
      }));
      this.titlePanel = nodeData.data.name;
      this.articleAnalysisContent = formattedContent;
      this.isSommairePanelVisible = true;
    },


    onDecisionClick(decisionData) {
      this.resetPanelData();
      console.log('Decision clicked:', decisionData);

      // Parse the pertinence/content of the decision
      const formattedContent = this.parseContent(decisionData.content);


      // Set the panel content for the decision
      this.titleDecision = decisionData.name;
      this.decisionAnalysisContent = formattedContent;
      this.decisionId = decisionData.decisionId;

      // Clear article-related content
      this.articleAnalysisContent = null;
      this.titleArticle = "";
      this.articleId = "";

      // Ensure the sommaire panel is visible
      this.isSommairePanelVisible = true;
    },
  }
};
</script>

<style scoped>

#decision-title-panel {
  margin-bottom: 5px;
}

.decision-link {
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

#decision-analysis {
  margin-top: 50px;
  border-top: #333;
}

.title-cartographie {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.fade-enter-active{
  transition: clip-path 1s ease-out, opacity 1s;
  clip-path: inset(0 0 0 0); /* Ends with the full element visible */
  opacity: 1;
}

.fade-enter {
  opacity: 0;
  clip-path: inset(0 0 100% 0); /* Start with the element fully clipped at the bottom */
}

.fade-leave-active {
  transition: clip-path 0.8s ease-out, opacity 0.8s;
  clip-path: inset(0 0 0 0); /* Ends with the full element visible */
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
  clip-path: inset(100% 0 0 0); /* Start with the element fully clipped at the bottom */
}

.point-title {
  font-size: 1.7rem;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.sommaire-columns {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.sommaire-section {
  flex: 1 1 45%; /* Adjust the percentage as needed */
  border-radius: 15px;
  padding: 7px;
  margin: 10px;
  box-sizing: border-box;
}

.sommaire-section h4 {
  font-size: 1.1em;
  margin-bottom: 5px;
}

.sommaire-section p {
  font-size: 1em;
  line-height: 1.4;
  text-align: justify;
}

@media (max-width: 600px) {
  .sommaire-section {
    flex: 1 1 100%;
  }
}


.summary {
  margin-bottom: 20px;
  height: 100%;
}

.summary ul {
  list-style: none;
  padding: 0;
}

.summary li {
  display: inline-block;
  margin: 0 10px;
}

.summary a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.summary a:hover {
  text-decoration: underline;
}

.text-panel {
  background-color: #f5efeb;
  overflow-y: auto;
  overflow-x: hidden;
  position:relative;
  width: 50%;
  display: block;
  padding: 20px;
  border: 1px solid #ccc;
  max-height: 100vh;
}

.texte-pertinence {
  margin: 15px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
}

.half-width {
  width: 50%!important;
}

.case-text {
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 0.8em;
  font-family: 'Roboto', sans-serif;
}

.citation-highlight {
  background-color: yellow;
  display: inline-block;
}

.card-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#modal-content-id {
  background-color: white;
  width: 82%;
  padding: 30px;
  border-radius: 25px;
  overflow-x: hidden;
  position: relative;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  float: right;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor:pointer;
  position: absolute;
  top: 25px;
  right: 25px;
}

h2, h3 {
  margin-top: 0;
  text-align: center;
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

li:last-child {
  border-bottom: none;
}

.summary ul ul {
  margin-left: 20px;
  list-style-type: disc;
}

.summary ul ul li {
  display: block; /* Makes each item take the full width */
  margin: 5px 0;
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Keeps the text within the bounds of the li element */
  text-overflow: ellipsis; /* Adds ellipsis if the text overflows */
}

.summary ul ul li a {
  font-weight: normal;
  color: #555;
  font-size: 0.9rem;
}

.summary ul ul li a:hover {
  text-decoration: underline;
}

#graph-container {
  width: 95%;
  height: 90vh;
  margin: 0 auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5%;
}

.links line {
  stroke: #999;
  stroke-opacity: 0.6;
}

.nodes circle {
  stroke: #fff;
  stroke-width: 1.5px;
}

.labels text {
  pointer-events: none;
  user-select: none;
}

.graph-container.with-panel {
  width: 60%; /* Adjust width when panel is visible */
}

.sommaire-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: #f5efeb;
  border-left: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 1000;
}

.sommaire-content {
  margin-top: 30px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
}


.sommaire-content h3 {
  margin-bottom: 25px;
  font-size: 1.25rem;
  margin-top: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  border: none;
  background: none;
}

.clickable {
  cursor: pointer; /* Changes cursor to pointer on hover */
  pointer-events: all; /* Ensures the element can receive pointer events */
}

</style>
