<template>
  <div class="container-main">
    <button style="display: flex;" @click="goToHome(false)">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-arrow-left-circle"
        viewBox="0 0 16 16"
        style="margin-right: 5px; margin-top: 4px"
      >
        <path
          fill-rule="evenodd"
          d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
        />
      </svg>
      Retour
    </button>
    <div class="form-container">
      <div class="contact-form">
        <div class="logo" style="display: flex; justify-content: center">
          <img
            src="@/assets/logo-query-juriste.png"
            alt="logo"
            style="width: 90px; height: 75px;"
          />
        </div>
        <div class="contact-equipe">
          <h2>Prenez contact avec notre équipe, nous reviendrons vers vous</h2>
        </div>
        <form @submit.prevent="submitForm">
          <!-- Group Nom and Prénom fields in a single row -->
          <div class="form-row">
            <div class="form-group">
              <label for="nom">Nom:</label>
              <input type="text" id="nom" v-model="form.nom" required />
            </div>

            <div class="form-group">
              <label for="prenom">Prénom:</label>
              <input type="text" id="prenom" v-model="form.prenom" required />
            </div>
          </div>

          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="form.email" required />
          </div>

          <div class="form-group">
            <label for="cabinet">Entreprise/Cabinet:</label>
            <input type="text" id="cabinet" v-model="form.cabinet" required />
          </div>

          <div class="form-group">
            <label for="message">Message (Optionnel):</label>
            <textarea id="message" v-model="form.message"></textarea>
          </div>

          <button class="sub-button" type="submit">Soumettre</button>
          <p v-if="responseMessage">{{ responseMessage }}</p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        nom: "",
        prenom: "",
        email: "",
        cabinet: "",
        message: "",
      },
      responseMessage: "",
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post("https://api.query-juriste.com/copilot/contact", this.form);
        if (response.data.res === "done") {
          this.responseMessage = "Your message has been sent!";
          this.clearForm();
        } else {
          this.responseMessage = "There was an error sending your message.";
        }
      } catch (error) {
        this.responseMessage = "An error occurred. Please try again later.";
      }
    },
    clearForm() {
      this.form.nom = "";
      this.form.prenom = "";
      this.form.email = "";
      this.form.cabinet = "";
      this.form.message = "";
      this.goToHome(true);
    },
    goToHome(sendMessage) {
      if (sendMessage) {
        this.$router.push({ path: '/homePage', query: { fromContact: true, message: this.responseMessage } });
      } else {
        this.$router.push('/homePage');
        // Yessir
      }
    },
  },
};
</script>

<style scoped>
.container-main {
  background-color: #f5efeb;
  font-family: "Roboto", sans-serif;
  padding: 2em;
  height: 100vh;
  width: 100vw !important;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.contact-form {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #f5efeb;
  width: 45%;
  padding: 2em;
  margin: 2em;
  border-radius: 5px;
}

/* New class to arrange Nom and Prénom side by side */
.form-row {
  display: flex;
  gap: 1rem; /* Space between Nom and Prénom fields */
}

.form-group {
  margin-bottom: 1rem;
  flex: 1; /* Ensures each field in a row takes equal space */
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

input,
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.sub-button {
  display: block;
  width: 100%;
  padding: 0.7rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.sub-button:hover {
  background-color: lightgrey;
}

p {
  margin-top: 1rem;
  color: green;
}

.contact-equipe {
  text-align: center;
  margin: 1em;
  font-size: 1.3rem;
}
</style>
