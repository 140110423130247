<template>
  <div class="connection-background" ref="container">
    <img src="../assets/logo-query-juriste.png" alt="logo" style="width: 15%; height: 20%;">
    <!-- Formulaire -->
    <transition name="slide-fade">
      <div class="form-container" v-if="formVisibleAfterSecondMessage">
        <img src="../assets/logo-query-juriste.png" alt="logo" style="width: 28%; height: 30%;">
        <form @submit.prevent="signin" class="form-horizontal">
          <div class="form-group">
            <div class="email-pre">
              <p><strong>{{ email }}</strong></p>
            </div>
            <input
              type="password"
              v-model="password"
              required
              class="form-control"
              :class="{ 'error-input': loginError }"
              autocomplete="current-password"
              placeholder="Mot de passe"
            >
            <div v-if="loginError" class="error-message">
              Il semble que cet e-mail est déjà utilisé
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="submit-button">Inscription</button>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.connection-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #f5efeb;
  font-family: 'Roboto', sans-serif;
  overflow-y: auto;
}

.welcome-text, .welcome-text-2 {
  margin-top: 4vh;
  margin-bottom: 3.5vh;
  font-size: 1.2em;
  width: 43%;
  text-align: left;
  color: #333;
}

.welcome-text-2 {
  margin-top: 2vh;
  margin-bottom: 35vh;
}

#question {
  margin-top: 1vh;
  color: #6b6969;
}

.email-pre {
  font-size: 1.1em;
  color: #333;
  text-align: center;
  margin-bottom: 2vh;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 1.5s, transform 1.5s;
  transition-timing-function: ease;
}

.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.cursor {
  animation: blink 0.7s steps(2, start) infinite;
  font-weight: bold;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.typing-container {
  white-space: pre-wrap;
}

.form-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 400px;
  height: 350px;
  border-radius: 10px;
  padding-top: 20px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25vh;
}

.form-container h2 {
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.form-horizontal .form-group {
  margin-bottom: 15px;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
}

.submit-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: black;
  color: white;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: lightgrey;
}

.error-input {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .form-container {
    width: auto;
    padding: 15px;
  }

  .connection-background {
    padding-top: 10px;
  }

  .form-container h2 {
    font-size: 1.5em;
  }

  .form-container {
    width: 80%;
    padding: 40px 20px;
  }
}

.signup-link {
  margin-top: 20px;
  text-align: center;
}

.signup-link a {
  color: #7e5cf2;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

.oauth-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid #7e5cf2;
  border-radius: 50px;
  background-color: white;
  color: #7e5cf2;
  font-size: 1em;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s, color 0.3s;
}

.oauth-button.google:hover {
  background-color: white;
  color: #6941c6;
  transform: scale(1.05);
}

.short-margin {
  margin-bottom: 8vh;
}

</style>

<script>
import { signUp, updateDocument } from '@/firebase/auth';

export default {
  data() {
    return {
      email: '',
      password: '',
      loginError: false,
      token: '',
      typedText: '',
      typing: true,
      typedText2: '',
      typing2: true,
      showSecondMessage: false,
      formVisible: false,
      formVisibleAfterSecondMessage: true,
      message: ``,
      message2: ``,
      questionMessage: '',
    };
  },
  created() {
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
    if (this.email === 'capucine.briand@whitecase.com') {
      this.questionMessage = '« Comment engager la responsabilité pénale d’une personne morale pour fraude financière sans dispositifs de conformité efficaces ? »';
    } else {
      this.questionMessage = '« Quelles sont les actions en responsabilité que les actionnaires minoritaires d\'une société cotée peuvent engager contre les dirigeants pour abus de pouvoir ou gestion abusive? »';
    }
    this.typeMessages();
  },
  methods: {
    async signin() {
      try {
        updateDocument(this.token);

        await signUp(this.email, this.password, true);
        this.$router.replace({ name: 'home' });
        this.loginError = false;
      } catch (error) {
        this.loginError = true;
      }
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.container;
        container.scroll({
          top: container.scrollHeight,
          behavior: 'smooth',
        });
      });
    },

    typeMessages() {
      let index = 0;
      const speed = 40;
      const type = () => {
        if (index < this.message.length) {
          this.typedText += this.message.charAt(index);
          index++;
          this.scrollToBottom();
          setTimeout(type, speed);
        } else {
          this.typing = false;
          this.formVisible = true;
          this.scrollToBottom();
          setTimeout(() => {
            this.showSecondMessage = true;
            this.typeSecondMessage();
            this.scrollToBottom();
          }, 2000);
        }
      };
      type();
    },
    typeSecondMessage() {
      let index = 0;
      const speed = 40;
      const type = () => {
        if (index < this.message2.length) {
          this.typedText2 += this.message2.charAt(index);
          index++;
          this.scrollToBottom();
          setTimeout(type, speed);
        } else {
          this.typing2 = false;
          setTimeout(() => {
            this.formVisibleAfterSecondMessage = true;
            this.scrollToBottom();
          }, 2000);
        }
      };
      type();
    },
  },
};
</script>
