<template>
  <div class="dossier-page bg-light">
    <SyntheseModal
      :isVisible="showSyntheseModal"
      :context="searchQuery"
      :dossierId="$route.params.dossierId"
      @startAnalysis="startAnalysis"
      @close="showSyntheseModal = false"
    />


    <header class="page-header">
      <h2 style="color: #1c1c1a"></h2>
      <h2 style="color: black; font-size: 1.5rem; font-weight: 150;">{{ dossierName }}</h2>
      <h2 style="color: #1c1c1a"></h2>
    </header>

    <div v-if="loading" class="loading">
      <button style= "display: flex;" @click="goBack">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16" style= "margin-right: 5px; margin-top: 4px">
          <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
        </svg>
        Retour
      </button>
      Chargement des décisions sauvegardées...
    </div>

    <div v-else>
      <div v-if="error" class="error">
        {{ error }}
      </div>
      <div v-else class="main-container">
        <div class="search-results" id="action-container">
          <button style= "display: flex; margin-bottom: 20px;" @click="goBack">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16" style= "margin-right: 5px; margin-top: 4px">
              <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
            </svg>
            Retour
          </button>
          <form class="search-form">
            <div class="input-container">
              <textarea
                v-model="searchQuery"
                :disabled="!isEditable"
                :class="{'error-input': searchError}"
                placeholder="Indiquez le contexte du dossier ici...
Copilote, votre agent de recherche, se chargera de l'analyser pour vous et vous fournira une synthèse juridique."
                class="search-input"
                rows="1"
                ref="searchBox"
                @input="adjustTextareaHeight"
              ></textarea>

            </div>
            <div class="buttons-container">
              <button
                type="button"
                @click="toggleEditing"
                class="search-button"
              >
                <!-- Dynamic SVG and Text -->
                <svg v-if="!isEditable" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                </svg>
                <span>{{ isEditable ? 'Sauvegarder' : '' }}</span>
              </button>
              <div v-if="isAnalysing === 'pending'" class="progress-bar-container">
                <div class="progress">
                  <div
                    class="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    :style="{ width: progressPercentage + '%' }"
                    :aria-valuenow="progressPercentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ Math.round(progressPercentage) }}%
                  </div>
                </div>
              </div>
              <div class="buttons-line">
                <button
                  type="button"
                  @click="handleAnalysisButtonClick"
                  :class="{'enrichir-button': true, 'pending-analysis': isAnalysing === 'pending'}"
                  :disabled="isAnalysing === 'pending'"
                  style="display: flex; align-items: center;"
                >
                  <template v-if="isAnalysing === 'pending'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="spinner-icon"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      style="margin-right: 5px;"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 3a5 5 0 1 1-4.546 2.916.5.5 0 1 1 .908-.418A4 4 0 1 0 8 4V1l3 3-3 3V4z"
                      />
                    </svg>
                    <p>Analyse en cours</p>
                  </template>
                  <template v-else-if="isAnalysing === 'done'">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-file-earmark-text"
                      viewBox="0 0 16 16"
                      style="margin-right: 3px"
                    >
                      <path
                        d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1
                          0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1
                          .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"
                      />
                      <path
                        d="M9.5 0H4a2 2 0 0
                          0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0
                          2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11
                          4.5h2V14a1 1 0 0 1-1
                          1H4a1 1 0 0 1-1-1V2a1 1 0 0
                          1 1-1z"
                      />
                    </svg>
                    <p>Voir la synthèse</p>
                  </template>
                  <template v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-file-earmark-text"
                      viewBox="0 0 16 16"
                      style="margin-right: 3px"
                    >
                      <path
                        d="M5.5 7a.5.5 0 0 0 0
                          1h5a.5.5 0 0 0 0-1zM5
                          9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1
                          0 1h-5a.5.5 0 0 1-.5-.5m0
                          2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1
                          0 1h-2a.5.5 0 0 1-.5-.5"
                      />
                      <path
                        d="M9.5 0H4a2 2 0 0
                          0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0
                          2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11
                          4.5h2V14a1 1 0 0 1-1
                          1H4a1 1 0 0 1-1-1V2a1 1 0 0
                          1 1-1z"
                      />
                    </svg>
                    <p>Synthèse Juridique Assistée</p>
                  </template>
                </button>
              </div>
            </div>
          </form>
        <div class="graph">
          <analysis-modal
            v-if="isAnalysing === 'done'"
            ref="analysisModal"
            :pointsList="analyses"
            :dossierName="dossierName"
          />
        </div>
        </div>
      </div>
    </div>
    <!-- TextModal for displaying full case text -->

    <TextModal
      :isVisible="showTextModal"
      :title="modalTitle"
      :text="modalText"
      @close="closeTextModal"
    />
  </div>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { collection, doc, getDocs, getDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import TextModal from './TextModal.vue';
import AnalysisModal from './AnalysisModal.vue';
import axios from 'axios';
import { getFirebaseToken } from '@/services/api';
import SyntheseModal from './SyntheseModal.vue';
import { onSnapshot } from 'firebase/firestore';

export default {
  name: 'DossierPage',
  components: {
    TextModal,
    AnalysisModal,
    SyntheseModal,
  },
  data() {
    return {
      cases: [],
      dossierName: '',
      showSyntheseModal: false,
      loading: true,
      error: null,
      activeCaseId: null,
      caseSummaries: {},
      showTextModal: false,
      showModal: false,
      analyses: [],
      modalText: '',
      modalTitle: '',
      searchQuery: '',
      searchError: false,
      isEditable: false,
      isAnalysing: 'pending', // Default value
      progressPercentage: 0,
      progressInterval: null,
    };
  },

  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.stopProgressBar(); // Clean up the progress interval
  },

  watch: {
    isAnalysing: {
      handler(newValue) {
        if (newValue === 'pending') {
          this.startProgressBar();
        } else {
          this.quickCompleteAndStopProgressBar();
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    // When component is mounted, check the analysis status
    await this.checkAnalysisStatus();
    if (this.isAnalysing === 'pending') {
      this.startProgressBar();
    }
    this.listenToAnalysisStatus();

    try {
      const userId = auth.currentUser.uid;
      const dossierId = this.$route.params.dossierId;

      // Fetch dossier name and context
      const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
      const dossierDoc = await getDoc(dossierDocRef);
      if (dossierDoc.exists()) {
        this.dossierName = dossierDoc.data().name;
        this.searchQuery = dossierDoc.data().contexte || ''; // Load existing context into the search box
      } else {
        this.error = 'Dossier non trouvé';
        return;
      }

      // Fetch cases in the dossier
      const casesRef = collection(db, 'users', userId, 'dossiers', dossierId, 'cases');
      const casesSnapshot = await getDocs(casesRef);
      this.cases = casesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error fetching cases:', error);
      this.error = 'Erreur lors de la récupération des décisions sauvegardées.';
    } finally {
      this.loading = false;
    }
  },

  methods: {
    handleAnalysisButtonClick() {
      console.log(this.isAnalysing);
      if (this.isAnalysing === 'pending') {
        // Optional: Inform the user that analysis is ongoing
        alert('L\'analyse est en cours. Veuillez patienter.');
      } else if (this.isAnalysing === 'done') {
        // Open the modal to display the analysis
        this.openAnalysisModal();
      } else {
        // Start the analysis
        this.showSyntheseModal = true;
      }
    },

    adjustTextareaHeight() {
      const textarea = this.$refs.searchBox;
      textarea.style.height = 'auto'; // Reset the height so it can shrink if text is removed
      textarea.style.height = `${Math.min(textarea.scrollHeight, this.$el.clientHeight)}px`;
    },

    goToSearch() {
      this.$router.replace({ name: 'Home' });
    },

    resetAnalysis() {
      if (confirm('Êtes-vous sûr de vouloir réinitialiser l\'analyse ?')) {
        this.isAnalysing = null; // Reset the analysis status
        this.synthesizeDossier(); // Save the empty context to Firebase
      }
    },

    async openAnalysisModal() {
      try {
        const userId = auth.currentUser.uid;
        const dossierId = this.$route.params.dossierId;

        const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
        const dossierDoc = await getDoc(dossierDocRef);

        if (dossierDoc.exists()) {
          const pointsList = dossierDoc.data().points_list || [];
          if (pointsList.length === 0) {
            alert('Aucune synthèse disponible pour ce dossier.');
            return;
          }
          // Assign the pointsList to analyses
          this.analyses = pointsList;
          this.showModal = true;
        } else {
          alert('Dossier non trouvé.');
        }
      } catch (error) {
        console.error('Error fetching analysis:', error);
        alert('Erreur lors de la récupération de la synthèse.');
      }
    },

    async checkAnalysisStatus() {
      try {
        const userId = auth.currentUser.uid;
        const dossierId = this.$route.params.dossierId;

        const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
        const dossierDoc = await getDoc(dossierDocRef);
        console.log(dossierDoc.data());

        if (dossierDoc.exists()) {
          this.isAnalysing = dossierDoc.data().isAnalysing; // Get the actual status
          console.log('Analysis status:', dossierDoc.data().isAnalysing);
          this.analyses = dossierDoc.data().points_list || [];
        } else {
          this.isAnalysing = null;
        }
      } catch (error) {
        console.error('Error checking analysis status:', error);
        this.isAnalysing = null;
      }
    },

    goBack() {
      this.$router.go(-1); // Navigates back to the previous page
    },

    toggleEditing() {
      if (this.isEditable) {
        // Save the context to Firebase when saving
        const userId = auth.currentUser.uid;
        const dossierId = this.$route.params.dossierId;
        const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
        updateDoc(dossierDocRef, {
          contexte: this.searchQuery
        }).then(() => {
          console.log("Context updated successfully");
          this.isEditable = false; // Disable editing mode
        }).catch((error) => {
          console.error("Error updating context:", error);
        });
      } else {
        // Enable editing
        this.isEditable = true;
        this.$nextTick(() => {
          this.$refs.searchBox.focus(); // Focus the search box
        });
      }
    },

    startAnalysis(context, pointList, visas_list) {
      this.startProgressBar();

        // Simulate analysis duration
        setTimeout(() => {
          this.isAnalysing = 'done';
          this.progressPercentage = 100;
          this.stopProgressBar();
        }, 440000); // Complete after 80 seconds
      // Start the analysis
      if (!context.trim()) {
        alert('Veuillez fournir un contexte pour le dossier.');
        return;
      }
      console.log('Launching analysis...');
      this.isAnalysing = 'pending';

      const dossierId = this.$route.params.dossierId;
      const userId = auth.currentUser.uid;
      const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);

      updateDoc(dossierDocRef, {
        contexte: context,
        isAnalysing: 'pending',
      });

      getFirebaseToken().then(token => {
        if (!token) {
          alert('Erreur d\'authentification. Veuillez réessayer.');
          return;
        }

        // Send POST request to the back-end endpoint with the token in the headers
        axios.post(`https://api.query-juriste.com/copilot/get_analyse`, {
          contexte: context,
          dossierId: dossierId,
          userId: userId,
          point_list: pointList,
          visas_list: visas_list
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(() => {
          this.$toast.success('La synthèse a été lancée avec succès. Cela peut prendre plusieurs minutes.');
          // Optionally update the state here
        }).catch(error => {
          console.error('Error starting analysis:', error);
          this.$toast.error('Erreur lors du lancement de la synthèse.');
        });
      }).catch(error => {
        console.error('Error fetching token:', error);
        this.$toast.error('Erreur lors de la récupération du token. Veuillez réessayer.');
      });
    },

    // async synthesizeDossier() {
    //   // Check if there is a context to send
    //   if (!this.searchQuery.trim()) {
    //     alert('Veuillez fournir un contexte pour le dossier.');
    //     return;
    //   }
    //   console.log('Launching analysis...');
    //   this.isAnalysing = 'pending';

    //   const dossierId = this.$route.params.dossierId;
    //   const context = this.searchQuery;
    //   const userId = auth.currentUser.uid;
    //   const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
    //   updateDoc(dossierDocRef, {
    //     context: this.searchQuery
    //   })
    //   getFirebaseToken().then(token => {
    //     if (!token) {
    //       alert('Erreur d\'authentification. Veuillez réessayer.');
    //       return;
    //     }

    //     // Send POST request to the back-end endpoint with the token in the headers
    //     axios.post(`https://api.query-juriste.com/copilot/get_analyse`, {
    //       contexte: context,
    //       dossierId: dossierId,
    //       userId: auth.currentUser.uid
    //     }, {
    //       headers: {
    //         'Authorization': `Bearer ${token}`
    //       }
    //     })
    //   }).catch(error => {
    //     console.error('Error fetching token:', error);
    //     alert('Erreur lors de la récupération du token. Veuillez réessayer.');
    //   });
    // },

    showAnalysisModal() {
      this.analyses = this.cases.map(caseItem => ({
        id: caseItem.id,
        title: caseItem.title,
        summary: `Analyse pour ${caseItem.title}` // Placeholder for actual analysis logic
      }));
      this.showModal = true;
    },

    createLink(result) {
      if (result.ID && result.ID.includes('TEXT')) {
        return `https://www.legifrance.gouv.fr/ceta/id/${result.ID}`;
      } else {
        return `https://www.courdecassation.fr/decision/${result.ID_judi}`;
      }
    },
    toggleSummary(clickedCaseId) {
      if (this.activeCaseId === clickedCaseId) {
        // If the same ID is clicked again, collapse it
        this.activeCaseId = null;
      } else {
        // Set the new ID and fetch data if necessary
        this.activeCaseId = clickedCaseId;
        if (!this.caseSummaries[clickedCaseId]) {
          const docRef = doc(db, 'cases', clickedCaseId);
          getDoc(docRef)
            .then(docSnap => {
              if (docSnap.exists()) {
                this.$set(this.caseSummaries, clickedCaseId, docSnap.data());
              } else {
                console.log('No case summary found for:', clickedCaseId);
              }
            })
            .catch(error => {
              console.error('Error fetching case summary:', error);
            });
        }
      }
    },
    openTextModal(caseId, title) {
      console.log('Opening text modal for case:', caseId);
      this.showTextModal = true;
      this.modalText = this.caseSummaries[caseId].texte;
      this.modalTitle = title;
    },
    closeTextModal() {
      this.showTextModal = false;
    },
    async removeCase(caseItem) {
      if (confirm('Êtes-vous sûr de vouloir supprimer cette décision du dossier ?')) {
        try {
          const userId = auth.currentUser.uid;
          const dossierId = this.$route.params.dossierId;
          const caseDocRef = doc(db, 'users', userId, 'dossiers', dossierId, 'cases', caseItem.id);
          await deleteDoc(caseDocRef);
          // Remove the case from the local array
          this.cases = this.cases.filter(c => c.id !== caseItem.id);
        } catch (error) {
          console.error('Error deleting case:', error);
          alert('Erreur lors de la suppression de la décision.');
        }
      }
    },

    startProgressBar() {
      this.progressPercentage = 0;
      const increment = 100 / 4400; // 0.125% per 100ms over 80 seconds
      this.progressInterval = setInterval(() => {
        if (this.progressPercentage < 100) {
          this.progressPercentage += increment;
          if (this.progressPercentage > 100) {
            this.progressPercentage = 100;
          }
        } else {
          this.stopProgressBar();
        }
      }, 100);
    },

    quickCompleteAndStopProgressBar() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
        this.progressPercentage = 100; // Quickly set to 100%
        setTimeout(() => {
          this.progressPercentage = 0; // Reset after a short delay
        }, 500); // Wait 500ms before disappearing
      }
    },

    stopProgressBar() {
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
        this.progressInterval = null;
      }
    },

    listenToAnalysisStatus() {
      const userId = auth.currentUser.uid;
      const dossierId = this.$route.params.dossierId;
      const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);

      this.unsubscribe = onSnapshot(
        dossierDocRef,
        (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            const previousStatus = this.isAnalysing;
            this.isAnalysing = data.isAnalysing;

            // Check if status changed from 'pending' to 'done'
            if (previousStatus === 'pending' && this.isAnalysing === 'done') {
              this.$toast.success('L\'analyse est terminée. Vous pouvez maintenant consulter la synthèse.');

              this.$nextTick(() => {
                if (
                  this.$refs.analysisModal &&
                  typeof this.$refs.analysisModal.createGraph === 'function'
                ) {
                  this.$refs.analysisModal.createGraph();
                } else {
                  console.warn('AnalysisModal component is not available or createGraph method is missing.');
                }
              });
            }
          }
        },
        (error) => {
          console.error('Error listening to analysis status:', error);
        }
      );
    },

    modifyContext() {
      const userId = auth.currentUser.uid;
      const dossierId = this.$route.params.dossierId;
      const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);

      // Update the context property with searchQuery
      updateDoc(dossierDocRef, {
        context: this.searchQuery
      }).then(() => {
        console.log("Context updated successfully");
        this.isEditable = false; // Disable editing mode
      }).catch((error) => {
        console.error("Error updating context:", error);
      });
    },

    handleBlur() {
      this.isEditable = false; // Make the search box non-editable when it loses focus
    },
    searchCases() {
      // Your search logic here
    },
  },
};
</script>

<style scoped>

.progress-bar-container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.pending-analysis {
    background-color: white!important;
    color: #1c1c1a!important;
    cursor: not-allowed!important;
    border: 1px solid #ccc!important;
    border-radius: 5px!important;
  }

.spinner-icon {
  animation: spin 1s linear infinite;
  margin-right: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.to-search-button, .enrichir-button, .back-button {
  background-color: #1c1c1a;
  color: #f4f4f4;
  border: none;
  padding: 9px;
  border-radius: 4px;
  font-size: 0.7rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions for background color and transform */
}

.to-search-button:hover, .enrichir-button:hover, .back-button:hover {
  background-color: #444; /* Darker shade on hover */
}

.to-search-button {
  background-color: #1c1c1a;
  align-self: center;
  color: #f4f4f4;
  border: none;
  padding: 9px;
  border-radius: 4px;
  width: 50%;
  font-size: 0.7rem;
  cursor: pointer;
}

.buttons-line {
  margin-top: 8px;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 24vh;
  width: 100%;
}

.buttons-container {
  display: flex;
  margin-left: 10%;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.search-form {

}

.search-input {
  width: 80%; /* Adjust the width to 70% and leave space for the margin */
  min-height: 24vh;
  max-height: 50vh;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #f4f4f4;
  box-sizing: border-box;
  resize: none; /* Prevent resizing */
}

.action-case-card {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.enrichir-button {
  background-color: #1c1c1a;
  color: #f4f4f4;
  border: none;
  padding: 9px;
  border-radius: 4px;
  font-size: 0.7rem;
  cursor: pointer;
}

#action-container {
  padding: 20px;
  background-color: white;
  width: 100%;
  margin: 0px;
}

.main-title {
  justify-content: center;  /* centers horizontally */
  align-items: center;
  display: flex;
  margin-bottom: 30px;
}

#action-title {
  margin-top: 40px;
}

.main-container {
  display: flex;
  height: 300vh;
}

.content-container {
  display: flex;
}

.page-header {
  background-color: #f4f4f4;
  height: 8.5vh;
  display: flex;
  align-items: center; /* Centers the items vertically in the container */
  justify-content: space-between;
}
.dossier-page {
  height: 100vh;
  font-family: 'Roboto', sans-serif;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.loading {
  font-size: 1.2em;
}

.error {
  color: red;
}

.search-results {
  width: 50%;
  margin: 20px;
}

.result-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ccc;
  background-color: #fff; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  margin-bottom: 10px; /* Space between items */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effects */
}

.result-item:hover {
  transform: translateY(-4px); /* Slight lift effect */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Enhanced shadow on hover */
}

.result-title {
  margin: 0 0 8px 0; /* Adjust spacing around the title */
  width: 93%;
  color: #333; /* Dark color for contrast */
  font-size: 1rem; /* Larger font size for titles */
}

.result-link {
  color: #007bff; /* Keep the blue link color */
  text-decoration: none; /* No underline */
  font-weight: bold; /* Make links bold for emphasis */
}

#titles {
  font-family: 0.7rem;
}

.result-link:hover {
  text-decoration: underline; /* Underline on hover for feedback */
}

.summary-toggle-button {
  font-size: 15px;  /* Slightly larger font size */
  margin-top: 12px; /* Margin top */
}

.case-details p {
    margin-bottom: 10px;  /* Adds space below each paragraph */
}

/* Optionally, you can add top margin to space it from any preceding content */
.case-details {
    margin-top: 20px;
}

.button-bookmark {
  width: 7%;
}

#title-bookmark {
  display: flex;
  justify-content: space-between;
}

.back-button {
  margin-top: 20px;
  background-color: #1c1c1a;
  color: #f4f4f4;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #444;
}

.small-arrow {
  font-size: 10px;  /* Set a smaller font size for the arrow */
  vertical-align: 1px;
}

.graph {
  margin-top: 15vh;
}
/* Adjust other styles as needed */

</style>
